const translation = {
  benefits: {
    benefitsTitle: "The benefits of using OptCotton",
    benefitsText1:
      "Artificial intelligence is increasingly present in the daily lives of people and companies, and",
    benefitsTextBold: "OptCotton brings AI to the mixing process ",
    benefitsText2:
      "with the aim of providing customers with safety, productivity and quality in production",

    seeMainBenefits: "See the main benefits:",

    noNeedSepareteTitle: "No need to separare inventory into classes",
    noNeedSepareteText:
      "This is most innovative methodology of bale management so far! Inventory categorization is part of the past!",

    shadeVariationTitle: "Efficient shade variations control",
    shadeVariationText:
      "Both the use of sophisticated algorithms for cotton laydowns and the reduction of variation in quality eliminate such risk.",

    uniformsLaydownsTitle: "Uniformed laydowns",
    uniformsLaydownsText:
      "In order to avoid variations in quality standards, OptCotton use both the inventory quality and that of the previous mixes then no intolerable variations will be accepted.",

    machineryEfficientlyTitle: "Machinery runs more efficiently",
    machineryEfficientlyText:
      "OptCotton uses intelligent algorithms to keep machinery running with fewer interruptions consequently increasing the efficiency of the spinning process.",

    hviParametersControlledTitle:
      "All HVI parameters controlled in the laydown.",
    hviParametersControlledText:
      "The system controls all the HVI parameters, in addition to the internal parameters required in each company.",

    controlInventoryTitle:
      "Total control of inventory, consumption and quality of the laydowns",
    controlInventoryText:
      "OptCotton allows, through control tools, reports and graphs, total control of the quantity and quality of the inventory, in addition to cotton consumption.",
  },
};

export default translation;
