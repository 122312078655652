import React, { useEffect, useRef } from "react";
import TestItForFree from "./pages/TestItForFree";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import CostumersMap from "./pages/CostumersMap";
import Footer from "./components/footer";
import Header from "./components/header";
import HowItWorks from "./pages/HowItWorks";
import Benefits from "./pages/Benefits";
import PrivacyPolicy from "./pages/privacyPolicy";
import NotFound from "./pages/Custom404";
import "./main.css";
import "./animation.css";
import CookiePopup from "./components/Cookies/CookiesPopup";

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
    const prevPathnameRef = useRef();
    useEffect(() => {
      if (pathname !== prevPathnameRef.current) {
        window.scrollTo(0, 0);
        prevPathnameRef.current = pathname;
      }
    }, [pathname]);

    return null;
  }
  return (
    <Router scrollToTop>
      <div className="wrapper">
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact key="home" path="/" element={<Home />} />
          <Route path="/about" key="about" element={<About />} />
          <Route path="/contact" key="contact" element={<Contact />} />
          <Route
            path="/test-it-for-free"
            key="test-it-for-free"
            element={<TestItForFree />}
          />
          <Route path="/costumers" key="costumers" element={<CostumersMap />} />
          <Route
            path="/how-it-works"
            key="how-it-works"
            element={<HowItWorks />}
          />

          <Route path="/benefits" key="benefits" element={<Benefits />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>

        <Footer />
        <CookiePopup />
      </div>
    </Router>
  );
}

export default App;
