import React from "react";
import "./style.css";
import OptPlanning from "./../../images/opt-planning.gif";
import OptPreLaydown from "./../../images/opt-prelaydown.gif";
import OptLaydown from "./../../images/opt-laydown.gif";
import OptCharts from "./../../images/opt-charts.gif";
import { useTranslation } from "react-i18next";

export default function HowItWorks() {
  const { t } = useTranslation();
  return (
    <div id="page-wrapper">
      <div className="page-title">{t("howItWorks.howItWorksTitle")}</div>
      <p>
        <span className="bold">OptCotton</span>{" "}
        {t("howItWorks.howItWorksText1")}
      </p>

      <p>{t("howItWorks.howItWorksText2")}</p>

      <p>{t("howItWorks.howItWorksText3")}</p>

      <p>{t("howItWorks.steps")}</p>

      {/* <div>
        <ul>
          <li className="bold">
            Control of the variation allowed for each quality parameter.
          </li>
          <li className="bold">
            Dosage of cotton use in laydowns according to cotton type, origin,
            producer, etc..
          </li>
          <li className="bold">Easy inventory management.</li>
          <li className="bold">Use of waste in mixtures.</li>
          <li className="bold">
            Configuration of each production line according to the yarn
            produced.
          </li>
          <li className="bold">
            More than 20 different types of reports for inventory control,
            consumption and quality.
          </li>
        </ul>
      </div> */}

      <p className="topics">{t("howItWorks.step1Title")}</p>
      <p>
        {t("howItWorks.step1Text")}{" "}
        <span className="bold">{t("howItWorks.step1TextBold")}</span>
      </p>
      <div className="opt-screenshot-wrapper">
        <img className="opt-screenshot" src={OptPlanning}></img>
      </div>

      <p className="topics">{t("howItWorks.step2Title")}</p>
      <p>
        {t("howItWorks.step2Text")}
        <span className="bold"> {t("howItWorks.step2TextBold")}</span>
      </p>
      <div className="opt-screenshot-wrapper">
        <img className="opt-screenshot" src={OptPreLaydown}></img>
      </div>

      <p className="topics">{t("howItWorks.step3Title")}</p>
      <p>{t("howItWorks.step3Text")}</p>
      <div className="opt-screenshot-wrapper">
        <img className="opt-screenshot" src={OptLaydown}></img>
      </div>

      <p className="topics">{t("howItWorks.step4Title")}</p>
      <p>{t("howItWorks.step4Text")}</p>
      <div className="opt-screenshot-wrapper">
        <img className="opt-screenshot" src={OptCharts}></img>
      </div>
    </div>
  );
}
