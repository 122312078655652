const translation = {
  testItForFree: {
    testItForFreeTitle: "Teste gratuitamente",
    testItForFreeText1:
      "Para que sua fiação possa ver na prática todas as funcionalidades do software e ter certeza de que ele pode te ajudar, estamos oferecendo uma ",
    testItForFreeText1Bold: "avaliação gratuita de dois meses do OptCotton.",
    testItForFreeText2:
      "Durante este período experimental, terá acesso a todas as funcionalidades do software sem quaisquer restrições, incluindo assistência e acompanhamento por uma equipa especializada.",
    testItForFreeText3:
      "O objetivo deste teste gratuito é permitir que você avalie OptCotton e comprove sua eficácia e segurança no processo de mistura. Além disso, você poderá se familiarizar com a interface e entender melhor o funcionamento do software.",
    disclaimerTitle: "Isenção de responsabilidade",
    disclaimerText1: "O período experimental destina-se a ",
    disclaimerTextBold: "01 (uma) linha de abertura ",
    disclaimerText2:
      "e estará sujeito a regulamentação interna e disponibilidade própria da WW SYSTEM respeitando uma sequência temporal de instalações. Nossa equipe de suporte estará disponível 24 horas por dia, 7 dias por semana, durante o período de teste de 02 meses, fornecendo treinamento, orientação e suporte técnico.",

    requirementsTitle: "Requerimentos técnicos:",
    requirement1:
      "Um computador laptop/desktop executando pelo menos o Windows 7 ou superior com RAM de pelo menos 1 GB e disco rígido de pelo menos 100 GB.",
    requirement2: "instalado para acesso remoto.",
    requirement3: "Acesso à Internet em todos os momentos.",
    requirement4:
      "Servidor de banco de dados. Uma instância existente do MSSQL Server ou Oracle Databse pode ser usada. caso a empresa não possua nenhuma instância de banco de dados rodando em sua infraestrutura, podemos instalar uma instância expressa do MSSQL Server (licença gratuita).",
    trainingTitle: "Treinamento",
    trainingText:
      "Para que sua equipe fique confortável e segura na utilização do software, oferecemos treinamento sobre as principais funções e funcionalidades do sistema. Idealmente, pelo menos uma pessoa em sua equipe deve ser alguém familiarizado com os parâmetros de qualidade da fibra, de preferência um gerente de qualidade.",
    formTitle:
      "Interessado? Responda algumas perguntas para que possamos conhecer um pouco mais sobre sua empresa.",
    emailSent: "Email enviado",
    emptyFields: "Preencha todos os campos",
  },
};
export default translation;
