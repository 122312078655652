const translation = {
  footer: {
    developed: "Developed by:",
    strategicPartner: "Parceiro estratégico:",
    footerText:
      "The methodology, technology and intelligence are protected by copyright law both in the USA, Brazil and Switzerland. ",
    patent: "Patent application US 10.815.589",
  },
};

export default translation;
