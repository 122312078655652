const translation = {
  howItWorks: {
    howItWorksTitle: "Como isso funciona",
    howItWorksText1:
      "é um software para elaboração e controle de misturas de algodão que controla todos os parâmetros de HVI, além dos parâmetros internos criados por cada empresa com o máximo de precisão e sem a necessidade de separar o inventário em classes.",
    howItWorksText2:
      "Sua principal finalidade é a eliminação de variações de qualidade entre as misturas para que o fio mantenha seu padrão de qualidade. O software gerencia o estoque desde a chegada dos fardos de algodão até seu consumo na linha de abertura.",
    howItWorksText3:
      "Planejado e desenvolvido por uma equipe com conhecimento tanto em tecnologia quanto em processos de mistura, o software oferece uma gama de recursos que proporcionam total controle sobre o processo de produção dos fios.",
    steps:
      "Resumidamente, estas são as etapas do processo de criação da mistura:",
    step1Title: "Primeiro, a definição de qualidade!",
    step1Text:
      "O processo de mixagem começa com o planejamento. Com base na qualidade do estoque disponível e na qualidade da última mistura, o software é capaz de calcular a qualidade aproximada da próxima disposição com o",
    step1TextBold: "simples clique de um botão.",
    step2Title: "Então, vamos pegar alguns fardos do inventário!",
    step2Text:
      "Na segunda etapa do processo, o sistema define quantos fardos serão necessários de cada lote para formar uma pré-mistura. No armazém, os fardos são separados fisicamente para o processo de seleção de acordo com a quantidade definida para cada lote.",
    step2TextBold: "Os fardos mais acessíveis podem ser pegos.",
    step3Title: "Vamos à seleção de fardos para a mistura.",
    step3Text:
      "Por meio de seu algoritmo, a OptCotton analisa as informações de cada fardo recolhido na etapa de pré-mistura e faz a seleção para que a qualidade média do fardo seja a mais próxima possível da qualidade planejada na etapa inicial. Os fardos selecionados são classificados posteriormente para que fiquem dispostos uniformemente na linha de abertura.",
    step4Title: "Processo planejado, executado e resultado alcançado.",
    step4Text:
      "Como resultado do processo de mistura planejado a partir da qualidade do estoque com uso de inteligência artificial, temos misturas com qualidade uniforme e estável.",
  },
};

export default translation;
