import React from "react";
//brasil
import alpina from "../../images/customers/alpina-textil.png";
import cooperfibra from "../../images/customers/cooperfibra.png";
import braxtex from "../../images/customers/Braxtex.jpg";
import coteminas from "../../images/customers/Coteminas.jpg";
import dohler from "../../images/customers/Dohler.png";
import estamparia from "../../images/customers/Estamparia.jpg";
import fabril from "../../images/customers/Fabril Mascarenhas.jpg";
import itabaiana from "../../images/customers/fiacaoitabaiana.png";
import fiasul from "../../images/customers/Fiasul.JPG";
import kyly from "../../images/customers/fio-puro.jpg";
import nb from "../../images/customers/NB.png";
import peripan from "../../images/customers/Peripan.png";
import saogeraldo from "../../images/customers/São Geraldo.png";
import tbm from "../../images/customers/TBM.png";
import tear from "../../images/customers/TearTextil.jpg";
import willrich from "../../images/customers/willrich.jpeg";
import Kurashiki from "../../images/customers/Kurashiki.png";
import PeDeSerra from "../../images/customers/Pe-de-serra.gif";
import PHB from "../../images/customers/PHB.png";
import TextilUniao from "../../images/customers/Textil-uniao.png";
import universal from "../../images/customers/universal.png";

//paraguai
import hoahi from "../../images/customers/Hoahi-Logo-01.png";

//paquistão
import tata from "../../images/customers/tata-pakistan.jpg";
import premium from "../../images/customers/Premium.png";
import indus from "../../images/customers/Indus-group.png";
import sapphire from "../../images/customers/sapphire.png";
import yunus from "../../images/customers/Yunus.png";
import mahmood from "../../images/customers/mahmood-group.png";

//mexico
import miro from "../../images/customers/grupo-miro.png";
import zagis from "../../images/customers/zagis.png";

//argentina
import ritex from "../../images/customers/Ritex.jpg";

//india
import ssa from "../../images/customers/saa_india.png";

//tailandia
import thai from "../../images/customers/TID-group.jpg";

//bangladesh

import nomam from "../../images/customers/Noman-group.png";
import saad from "../../images/customers/SAAD-group.jpg";

import "./style.css";

export default function AnnotationTemplate(country) {
  const brasil = [
    alpina,
    cooperfibra,
    braxtex,
    coteminas,
    dohler,
    estamparia,
    fabril,
    itabaiana,
    fiasul,
    kyly,
    nb,
    peripan,
    saogeraldo,
    tbm,
    tear,
    willrich,
    Kurashiki,
    PeDeSerra,
    PHB,
    TextilUniao,
    universal,
  ];
  const india = [ssa];
  const tailandia = [thai];
  const argentina = [ritex];
  const paraguai = [hoahi];
  const mexico = [miro, zagis];
  const paquistao = [tata, premium, indus, sapphire, yunus, mahmood];
  const bangladesh = [saad, nomam];

  if (country.attribute("name") === "Brazil") {
    return (
      <div>
        <div className="customers-tooltip-country">Brasil</div>
        <div className="customers-tooltip-wrapper">
          {brasil.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  }

  /*   if (country.attribute("name") === "Bangladesh") {
    return (
      <div>
        <div className="customers-tooltip-country">Bangladesh</div>
        <div className="customers-tooltip-wrapper">
          {bangladesh.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  } */

  if (country.attribute("name") === "India") {
    return (
      <div>
        <div className="customers-tooltip-country">India</div>
        <div className="customers-tooltip-wrapper">
          {india.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  }

  if (country.attribute("name") === "Thailand") {
    return (
      <div>
        <div className="customers-tooltip-country">Thailand</div>
        <div className="customers-tooltip-wrapper">
          {tailandia.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  }

  if (country.attribute("name") === "Argentina") {
    return (
      <div>
        <div className="customers-tooltip-country">Argentina</div>
        <div className="customers-tooltip-wrapper">
          {argentina.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  }

  if (country.attribute("name") === "Paraguay") {
    return (
      <div>
        <div className="customers-tooltip-country">Paraguay</div>
        <div className="customers-tooltip-wrapper">
          {paraguai.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  }

  if (country.attribute("name") === "Mexico") {
    return (
      <div>
        <div className="customers-tooltip-country">Mexico</div>
        <div className="customers-tooltip-wrapper">
          {mexico.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  }

  if (country.attribute("name") === "Pakistan") {
    return (
      <div>
        <div className="customers-tooltip-country">Pakistan</div>
        <div className="customers-tooltip-wrapper">
          {paquistao.map((image) => (
            <img
              className="customers-tooltip-logo"
              src={image}
              alt="Descrição da imagem"
              key={image}
            />
          ))}
        </div>
      </div>
    );
  }
}
