const translation = {
  footer: {
    developed: "Desenvolvido por:",
    strategicPartner: "Parceiro estratégico:",
    footerText:
      "A metodologia, tecnologia e inteligência são protegidas pela lei de direitos autorais de EUA, Brasil e Suíça. ",
    patent: "Patente US 10.815.589",
  },
};

export default translation;
