const translation = {
  privacyPolicy: {
    popupMessage:
      "Este sitio web utiliza cookies. Al continuar navegando, acepta el uso de cookies",
    learnMore: "saber mas",
    accept: "Aceptar",
    deny: "Denegar",
    title: "Política de privacidad de cookies",
    description:
      "Esta Política de privacidad de cookies describe cómo el sitio web optcotton.com utiliza cookies y tecnologías similares cuando visita o interactúa con el sitio web. Esta política también explica cuáles son sus derechos con respecto al uso de cookies y cómo puede controlar sus preferencias.",
    consent: "Consentir",
    consentText:
      "Al utilizar el sitio web optcotton.com, acepta el uso de cookies de acuerdo con esta Política de privacidad de cookies. Si no está de acuerdo con el uso de cookies, le recomendamos que ajuste la configuración de su navegador o deje de usar el sitio web optcotton.com.",
    cookiesType: "Tipos de cookies utilizadas",
    cookiesTypeText:
      "El sitio web optcotton.com puede utilizar los siguientes tipos de cookies:",
    cookiesType1:
      "Cookies esenciales: Son cookies necesarias para el funcionamiento básico del sitio web. Le permiten navegar por el sitio y usar funciones esenciales, como iniciar sesión en una cuenta.",
    cookiesType2:
      "Cookies de rendimiento: estas cookies recopilan información sobre cómo los visitantes usan el sitio web, como las páginas visitadas, el tiempo que pasan en el sitio web y los errores encontrados. Esta información se utiliza para mejorar el rendimiento y la usabilidad del sitio web.",
    cookiesType3:
      "Cookies de funcionalidad: estas cookies permiten que el sitio web recuerde sus preferencias, como el idioma que elige o la región en la que se encuentra. Esto proporciona una experiencia personalizada durante su visita al sitio.",
    cookiesType4:
      "Cookies publicitarias: podemos permitir que terceros muestren anuncios en el sitio web optcotton.com. Estas cookies recopilan información sobre sus intereses en función de sus interacciones anteriores con el sitio web, lo que permite a los anunciantes mostrar anuncios que son relevantes para usted.",
    cookiesControl: "Control de cookies",
    cookiesControlText:
      "Tiene derecho a controlar y administrar sus preferencias de cookies. Puede ajustar la configuración de su navegador para bloquear o eliminar las cookies, así como establecer preferencias para las cookies de terceros. Tenga en cuenta que al bloquear o eliminar las cookies, es posible que algunas funciones del sitio web optcotton.com no funcionen correctamente.",
    links: "Enlaces a otros sitios web",
    linksText:
      "El sitio web optcotton.com puede contener enlaces a otros sitios web que tienen sus propias políticas de privacidad. Le recomendamos que revise las políticas de privacidad de estos sitios, ya que no tenemos control sobre sus cookies y prácticas de privacidad.",
    policyChanges: "Cambios en la Política de Privacidad de Cookies",
    policyChangesText:
      "Nos reservamos el derecho de realizar cambios en esta Política de privacidad de cookies en cualquier momento. Cualquier cambio entrará en vigencia inmediatamente después de la publicación de la política actualizada en el sitio web optcotton.com.",
    questions:
      "Si tiene alguna pregunta o inquietud acerca de nuestra Política de privacidad de cookies, comuníquese con nosotros utilizando los datos de contacto proporcionados en el sitio web optcotton.com.",
    implementation: "Fecha de vigencia: 23/06/2023.",
  },
};
export default translation;
