const translation = {
  benefits: {
    benefitsTitle: "Los beneficios de usar OptCotton",
    benefitsText1:
      "La inteligencia artificial está cada vez más presente en el día a día de personas y empresas, y",

    benefitsTextBold: "OptCotton lleva la IA al proceso",
    benefitsText2:
      "de mezcla con el objetivo de brindar a los clientes seguridad, productividad y calidad en la producción",

    seeMainBenefits: "Vea los principales beneficios:",

    noNeedSepareteTitle: "No es necesario separar el inventario en clases",
    noNeedSepareteText:
      "¡Esta es la metodología más innovadora de gestión de pacas hasta ahora! ¡La categorización del inventario es parte del pasado!",

    shadeVariationTitle: "Control eficiente de las variaciones de color",
    shadeVariationText:
      "Tanto el uso de algoritmos sofisticados para las mezclas de algodón como la reducción de la variación en la calidad eliminan dicho riesgo.",

    uniformsLaydownsTitle: "Mezclas uniformes",
    uniformsLaydownsText:
      "Para evitar variaciones en los estándares de calidad, OptCotton utiliza tanto la calidad del inventario como la de las mezclas anteriores, por lo que no se aceptarán variaciones intolerables.",

    machineryEfficientlyTitle: "La maquinaria funciona de manera más eficiente",
    machineryEfficientlyText:
      "OptCotton utiliza algoritmos inteligentes para mantener la maquinaria en funcionamiento con menos interrupciones, lo que aumenta la eficiencia del proceso de hilado.",

    hviParametersControlledTitle:
      "Todos los parámetros HVI controlados en la mezcla.",
    hviParametersControlledText:
      "El sistema controla todos los parámetros del HVI, además de los parámetros internos requeridos en cada empresa.",

    controlInventoryTitle:
      "Control total de inventario, consumo y calidad de las mezclas",
    controlInventoryText:
      "OptCotton permite, a través de herramientas de control, reportes y gráficos, el control total de la cantidad y calidad del inventario, además del consumo de algodón.",
  },
};

export default translation;
