import React, { useState, useMemo, useRef } from "react";
import emailjs from "@emailjs/browser";
import CountrySelect from "react-country-select";
import Select from "react-select";
import countryList from "react-select-country-list";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

export default function SpinningMillForm() {
  const { t } = useTranslation();
  const [isSpinningMill, setIsSpinningMill] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [hviMachine, setHviMachine] = useState("");
  const [monthlyCottonConsumption, setMonthlyCottonConsumption] = useState("");
  const [productionLines, setProductionLines] = useState("");
  const [hasQualityManager, setHasQualityManager] = useState("");
  const [isBalesTested, setIsBalesTested] = useState("");
  const [balesWarehouse, setBalesWarehouse] = useState("");
  const [hasQualityManagerTraining, setHasQualityManagerTraining] =
    useState("");
  const [trialStartDate, setTrialStartDate] = useState("");
  const [nameAndAddress, setNameAndAddress] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const refCaptcha = useRef(); // <- add the useRef hook

  function sendEmail(e) {
    e.preventDefault();

    const token = refCaptcha.current.getValue();
    refCaptcha.current.reset();

    if (
      isSpinningMill == "" ||
      location == "" ||
      phone == "" ||
      email == "" ||
      location == "" ||
      nameAndAddress == ""
    ) {
      alert(t("testItForFree.emptyFields"));
    } else if (token == null) {
      alert("Recaptcha null");
    } else {
      const templateParams = {
        phone,
        email,
        location: location.label,
        nameAndAddress,
        hviMachine,
        monthlyCottonConsumption,
        productionLines,
        hasQualityManager,
        isBalesTested,
        balesWarehouse,
        hasQualityManagerTraining,
        trialStartDate,
        "g-recaptcha-response": token,
      };

      emailjs
        .send(
          "service_6ewvcr9",
          "template_x8cmsvp",
          templateParams,
          "mUpL9eoa_ErEdHSnA"
        )
        .then(
          (response) => {
            console.log("email enviado", response.status, response.text);
            alert(t("testItForFree.emailSent"));

            setBalesWarehouse("");
            setEmail("");
            setHasQualityManager("");
            setHasQualityManagerTraining("");
            setHviMachine("");
            setIsBalesTested("");
            setIsSpinningMill("");
            setLocation("");
            setMonthlyCottonConsumption("");
            setNameAndAddress("");
            setPhone("");
            setProductionLines("");
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  return (
    <form id="myForm" onSubmit={sendEmail}>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="isSpinningMill">{t("form.question1")}</label>
        </div>
        <div className="form-input-wrapper">
          <select
            className="form-input"
            id="isSpinningMill"
            value={isSpinningMill}
            onChange={(e) => setIsSpinningMill(e.target.value)}
          >
            <option value="">{t("form.selectOption")}</option>
            <option value="yes">{t("form.question1Options.option1")}</option>
            <option value="no">{t("form.question1Options.option2")}</option>
          </select>
        </div>
      </div>
      <div className="form-label-wrapper">
        <div className="form-label-wrapper">
          <label htmlFor="location">
            {t("form.question2")} <span>*</span>
          </label>
        </div>
        <div className="form-input-wrapper-location">
          <Select
            options={options}
            value={location}
            onChange={(value) => setLocation(value)}
          />
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="email">
            {t("form.question3")} <span>*</span>
          </label>
        </div>
        <div className="form-input-wrapper">
          <input
            className="form-input"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="phone">{t("form.question4")}</label>
        </div>
        <div className="form-input-wrapper">
          <input
            className="form-input"
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="hviMachine">{t("form.question5")}</label>
        </div>
        <div className="form-input-wrapper">
          <select
            className="form-input"
            id="hviMachine"
            value={hviMachine}
            onChange={(e) => setHviMachine(e.target.value)}
          >
            <option value="">{t("form.selectOption")}</option>
            <option value="Yes, my spinning mill has same sort of HVI machine">
              {t("form.question5Options.option1")}
            </option>
            <option value="No, We don't have anything similar.">
              {t("form.question5Options.option2")}
            </option>
            <option value=" Although our spinning mill does have, We don't use it.">
              {t("form.question5Options.option3")}
            </option>
            <option value="No, but We intend to purchase it in a near future">
              {t("form.question5Options.option4")}
            </option>
          </select>
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="monthlyCottonConsumption">
            {t("form.question6")}
          </label>
        </div>
        <div className="form-input-wrapper">
          <input
            className="form-input"
            type="number"
            id="monthlyCottonConsumption"
            value={monthlyCottonConsumption}
            onChange={(e) => setMonthlyCottonConsumption(e.target.value)}
          />
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="productionLines">{t("form.question7")}</label>
        </div>
        <div className="form-input-wrapper">
          <input
            className="form-input"
            type="number"
            id="productionLines"
            min={0}
            value={productionLines}
            onChange={(e) => setProductionLines(e.target.value)}
          />
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="hasQualityManager">
            {t("form.question8")}
            <span>*</span>
          </label>
        </div>
        <div className="form-input-wrapper">
          <select
            className="form-input"
            id="hasQualityManager"
            value={hasQualityManager}
            onChange={(e) => setHasQualityManager(e.target.value)}
          >
            <option value="">{t("form.selectOption")}</option>
            <option value="yes">{t("form.question8Options.option1")}</option>
            <option value="no">{t("form.question8Options.option2")}</option>
          </select>
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="isBalesTested">{t("form.question9")}</label>
        </div>
        <div className="form-input-wrapper">
          <select
            className="form-input"
            id="isBalesTested"
            value={isBalesTested}
            onChange={(e) => setIsBalesTested(e.target.value)}
          >
            <option value="">{t("form.selectOption")}</option>
            <option value="Yes, 100% of the bales have been tested">
              {t("form.question9Options.option1")}
            </option>
            <option value="No, but about 50% of the bales have been tested">
              {t("form.question9Options.option2")}
            </option>
            <option value="No, but about 30% of the bales have been tested">
              {t("form.question9Options.option3")}
            </option>
            <option value="No, but 10% of the bales have been tested">
              {t("form.question9Options.option4")}
            </option>
            <option
              value="No, Although nothing has been tested, We plan to strat testing them
          shortly."
            >
              {t("form.question9Options.option5")}
            </option>
          </select>
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="balesWarehouse">{t("form.question10")}</label>
        </div>
        <div className="form-input-wrapper">
          <select
            className="form-input"
            id="balesWarehouse"
            value={balesWarehouse}
            onChange={(e) => setBalesWarehouse(e.target.value)}
          >
            <option value="">{t("form.selectOption")}</option>
            <option value="lot wise">
              {t("form.question10Options.option1")}
            </option>
            <option value="container wise">
              {t("form.question10Options.option2")}
            </option>
            <option value="lorry wise">
              {t("form.question10Options.option3")}
            </option>
            <option value="some other methodology">
              {t("form.question10Options.option4")}
            </option>
          </select>
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="hasQualityManagerTraining">
            {t("form.question11")}
          </label>
        </div>
        <div className="form-input-wrapper">
          <select
            className="form-input"
            id="hasQualityManagerTraining"
            value={hasQualityManagerTraining}
            onChange={(e) => setHasQualityManagerTraining(e.target.value)}
          >
            <option value="">{t("form.selectOption")}</option>
            <option value="yes">{t("form.question11Options.option1")}</option>
            <option value="no">{t("form.question11Options.option2")}</option>
          </select>
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="trialStart">{t("form.question12")}</label>
        </div>
        <div className="form-input-wrapper">
          <input
            className="form-input"
            type="date"
            id="trialStart"
            value={trialStartDate}
            onChange={(e) => setTrialStartDate(e.target.value)}
          />
        </div>
      </div>
      <div className="form-item">
        <div className="form-label-wrapper">
          <label htmlFor="name">
            {t("form.question13")} <span>*</span>
          </label>
        </div>
        <div className="form-input-wrapper">
          <textarea
            className="form-input"
            id="name"
            value={nameAndAddress}
            onChange={(e) => setNameAndAddress(e.target.value)}
          />
        </div>
      </div>

      <ReCAPTCHA
        ref={refCaptcha}
        sitekey="6LdZdQ8mAAAAAAbQjtTng2Eb3HWh0jgGING2tab9"
      />

      <div>
        <button className="main-button-blue" type="submit">
          {t("buttons.submit")}
        </button>
      </div>
    </form>
  );
}
