const translation = {
  home: {
    appTitle: "最高效的棉包管理软件！",
    appDescription:
      "OptCotton可控制混合棉性质，保持质量稳定，从而消除色差等问题。",

    itmaInvitation: "参观我们在ITMA 2023的展位",
    itmaDescription: "全球最大的国际纺织服装技术展。",
    itmaLocale: "我们的展位",
    itmaDate: "2023年6月8日-14日",

    howItWorks: "如何运行？",
    howItWorksText:
      "从棉花入库开始，OptCotton对整个配棉过程进行管理，生成质量统一的混合棉，确保配棉过程的安全性",

    mixControlProcess: "全面控制配棉过程",
    mixControlProcessText:
      "我们的系统使用来自任何HVI源的所有性质参数。从现有库存的质量到开包机上的棉包排列，整个配棉过程都经过精确规划。",
    mixControlProcessImg1: "质量规划",
    mixControlProcessImg2: "棉包分离",
    mixControlProcessImg3: "棉包选择",
    mixControlProcessImg4: "棉包排列",

    benefitSlider: {
      benefitTitle1: "有效控制色差",
      benefitText1:
        "通过先进的算法对混合棉进行分析，减少质量差异，消除出现色差的风险。",

      benefitTitle2: "均匀混棉",
      benefitText2:
        "为了避免成纱品质的变化，OptCotton将库存原棉质量和前一批混合棉质量进行对比，避免出现不可接受的差异。",

      benefitTitle3: "无需对库存分类",
      benefitText3: "采用独特的方法，无需将仓库中的棉包按质量分类。",

      benefitTitle4: "在配棉过程中控制所有HVI参数",
      benefitText4: "系统可控制所有HVI参数，以及每家企业所要求的内部参数。",
    },

    aiTitle: "纺织业的人工智能",
    aiText:
      "纺织业的未来依赖于通过人工智能来配棉。通过算法，系统能够分析和选择棉包进行棉层铺放，为配棉过程带来稳定性和安全性。",

    customersTitle: "我们的客户",
    customersText:
      "我们目前在美洲和亚洲的11个国家开展业务，占全球配棉总量的2%。以下是我们的一些客户。",
  },
};

export default translation;
