import React from "react";
import "./../../main.css";
import LogoOptCotton from "./../../images/Logo_Opt_branco.png";
import OptDevices from "./../../images/opt-devices.png";
import OptMobilePerson from "./../../images/opt-mobile-pesron.png";
/* import ItmaLogo from "./../../images/itma-logo.png"; */
import { useNavigate } from "react-router-dom";
import BenefitsSlider from "../../components/BenefitsSlider";
import CustomerSlider from "../../components/CustomersSlider";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = i18next.language;

  function handleButtonClick(path) {
    navigate(path);
  }

  function getCookie(name) {
    const match = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    if (match) return match[2];
  }

  function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  }

  window.addEventListener("DOMContentLoaded", function () {
    const cookiePopup = document.getElementById("cookie-popup");
    const acceptButton = document.getElementById("accept-cookies");

    if (getCookie("cookiesAccepted")) {
      cookiePopup.style.display = "none";
    } else {
      cookiePopup.style.display = "flex";
    }

    acceptButton.addEventListener("click", function () {
      setCookie("cookiesAccepted", true, 365);
      cookiePopup.style.display = "none";
    });
  });

  return (
    <div id="home-wrapper">
      <div id="home-main-bg">
        <div id="home-main-bg-filter" className="blue-filter">
          <div>
            <img
              id="logo-optcotton-home"
              src={LogoOptCotton}
              className="zoomIn"
            ></img>
            <div id="app-title" className="zoomIn">
              {t("home.appTitle")}
            </div>
            <div id="app-description" className="zoomIn">
              {t("home.appDescription")}
            </div>
            <div id="home-main-btn-wrapper">
              <div>
                <button
                  className="main-button-white zoomIn"
                  onClick={() => handleButtonClick("/test-it-for-free")}
                >
                  {t("buttons.testItForFree")}
                </button>
              </div>
              <div>
                <button
                  className="main-button-white zoomIn"
                  onClick={() => handleButtonClick("/how-it-works")}
                >
                  {t("buttons.howItWorks")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="how-it-works-wrapper">
        <div id="how-it-works-text-wrapper">
          <div style={{ marginBottom: 15 }} className="blue-text-home">
            {t("home.howItWorks")}
          </div>
          <div id="how-it-works-text">{t("home.howItWorksText")}</div>
          <div style={{ marginTop: 30 }}>
            <button
              id="btn-how-it-works"
              className="main-button-blue"
              onClick={() => handleButtonClick("/how-it-works")}
            >
              {t("buttons.howItWorks")}
            </button>
          </div>
        </div>
        <div id="how-it-works-images-wrapper">
          <img className="how-it-works-img" src={OptDevices}></img>
        </div>
      </div>

      <div id="mixing-control-wrapper">
        <div id="mixing-control-text">
          <div style={{ marginBottom: 15 }} className="blue-text-home">
            {t("home.mixControlProcess")}
          </div>
          <div>{t("home.mixControlProcessText")}</div>
        </div>

        <div id="mixing-control-topics-wrapper">
          <div className="mixing-control-topics-item">
            <div>
              <div className="mixing-control-topics-bg">
                <div
                  id="mixing-control-topics-item1"
                  className="mixing-control-topics-img"
                ></div>
              </div>
              <div className="mixing-control-topics-text">
                {t("home.mixControlProcessImg1")}
              </div>
            </div>
          </div>
          <div className="mixing-control-topics-item">
            <div>
              <div className="mixing-control-topics-bg">
                <div
                  id="mixing-control-topics-item2"
                  className="mixing-control-topics-img"
                ></div>
              </div>
              <div className="mixing-control-topics-text">
                {t("home.mixControlProcessImg2")}
              </div>
            </div>
          </div>
          <div className="mixing-control-topics-item">
            <div>
              <div className="mixing-control-topics-bg">
                <div
                  id="mixing-control-topics-item3"
                  className="mixing-control-topics-img"
                ></div>
              </div>
              <div className="mixing-control-topics-text">
                {t("home.mixControlProcessImg3")}
              </div>
            </div>
          </div>
          <div className="mixing-control-topics-item">
            <div>
              <div className="mixing-control-topics-bg">
                <div
                  id="mixing-control-topics-item4"
                  className="mixing-control-topics-img"
                ></div>
              </div>
              <div className="mixing-control-topics-text">
                {t("home.mixControlProcessImg4")}
              </div>
            </div>
          </div>
        </div>

        {/* mostra video em ingles */}

        <div
          className="video-container"
          style={language != "en" ? { display: "none" } : {}}
        >
          <iframe
            width="500"
            height="275"
            src="https://www.youtube.com/embed/U8smgucfkG8"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        {/* //mostrar video em portugues */}

        <div
          className="video-container"
          style={
            language != "pt-br" && language != "pt-BR"
              ? { display: "none" }
              : {}
          }
        >
          <iframe
            width="500"
            height="275"
            src="https://www.youtube.com/embed/ra8FM0K2tg4"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        {/* //mostrar video em espanhol */}

        <div
          className="video-container"
          style={language != "es" ? { display: "none" } : {}}
        >
          <iframe
            width="500"
            height="275"
            src="https://www.youtube.com/embed/3_PBN8cYvLk"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div id="main-benefits-wrapper">
        <div id="main-benefits-filter" className="blue-filter">
          <BenefitsSlider />
          <div>
            <button
              id="btn-customers-list"
              className="main-button-white"
              onClick={() => handleButtonClick("/benefits")}
            >
              {t("buttons.allBenefits")}
            </button>
          </div>
        </div>
      </div>

      <div id="ai-text-wrapper">
        <div id="ai-text">
          <div style={{ marginBottom: 15 }} className="blue-text-home">
            {t("home.aiTitle")}
          </div>
          <div>{t("home.aiText")}</div>
        </div>
        <div id="ai-img">
          <img id="img-mobile-person" src={OptMobilePerson}></img>
        </div>
      </div>

      <div id="customers-text-wrapper">
        <div style={{ paddingBottom: 20 }} className="blue-text-home">
          {t("home.customersTitle")}
        </div>
        <div style={{ marginBottom: 20 }}>{t("home.customersText")}</div>
        <CustomerSlider />

        <div style={{ marginTop: 20 }}>
          <button
            id="btn-customers-list"
            className="main-button-blue"
            onClick={() => handleButtonClick("/costumers")}
          >
            {t("buttons.seeOurCustomerList")}
          </button>
        </div>
      </div>
    </div>
  );
}
