const translation = {
  testItForFree: {
    testItForFreeTitle: "Prueba gratis",
    testItForFreeText1:
      "Para que sus fábricas de algodón puedan ver toda la funcionalidad del software en la práctica y estar seguros de que puede ayudarlo, ofrecemos un ",
    testItForFreeText1Bold: "prueba gratuita de dos meses de OptCotton.",
    testItForFreeText2:
      "Durante este período de prueba, tendrá acceso a todas las funcionalidades del software sin ninguna restricción, incluida la asistencia y seguimiento por parte de un equipo especializado.",
    testItForFreeText3:
      "El propósito de esta prueba gratuita es permitirle evaluar OptCotton y demostrar su eficacia y seguridad en el proceso de mezcla. Además, podrá familiarizarse con la interfaz y comprender mejor cómo funciona el software.",
    disclaimerTitle: "Descargo de responsabilidad",
    disclaimerText1: "El período de prueba está destinado a ",
    disclaimerTextBold: "01 (una) línea de operación ",
    disclaimerText2:
      "y estará sujeta a la normativa interna y disponibilidad propias de WW SYSTEM respetando una secuencia temporal de instalaciones. Nuestro equipo de soporte estará disponible las 24 horas del día, los 7 días de la semana durante el período de prueba de 02 meses, brindando capacitación, orientación y soporte técnico.",

    requirementsTitle: "Requerimientos técnicos:",
    requirement1:
      "Una computadora laptop/desktop que ejecute al menos Windows 7 o superior con una memoria RAM de al menos 1 GB y un disco duro de al menos 100 GB.",
    requirement2: "instalado para acceso remoto.",
    requirement3: "Acceso a Internet en todo momento.",
    requirement4:
      "Servidor de base de datos. Se puede utilizar una instancia existente de MSSQL Server u Oracle Databse. si la empresa no tiene ninguna instancia de base de datos funcionando en su infraestructura, podemos instalar una instancia express de MSSQL Server (licencia gratis).",
    trainingTitle: "Capacitación",
    trainingText:
      "Para que su equipo se sienta cómodo y seguro al usar el software, brindamos capacitación sobre las principales funciones y características del sistema. Idealmente, al menos una persona en su equipo debería estar familiarizada con los parámetros de calidad de la fibra, preferiblemente un gerente de calidad.",
    formTitle:
      "¿Interesado? Responda algunas preguntas para que podamos aprender un poco más sobre su empresa.",
    emailSent: "Email enviado",
    emptyFields: "Preencha todos os campos",
  },
};
export default translation;
