const translation = {
  benefits: {
    benefitsTitle: "使用OptCotton的优势",
    benefitsText1: "人工智能在生活和生产中的使用日益普遍，",
    benefitsTextBold: "OptCotton将人工智能引入配棉过程",
    benefitsText2: "户提供安全、高效和高质量的生产过程。",

    seeMainBenefits: "主要优势包括：",

    noNeedSepareteTitle: "无需对库存分类",
    noNeedSepareteText:
      "这是迄今为止最创新的棉包管理方法！让库存分类成为过去！",

    shadeVariationTitle: "有效控制色差",
    shadeVariationText:
      "通过先进的算法对混合棉进行分析，减少质量差异，消除出现色差的风险。",

    uniformsLaydownsTitle: "均匀混合",
    uniformsLaydownsText:
      "为了避免成纱品质的变化，OptCotton将库存原棉质量和前一批混合棉质量进行对比，避免出现不可接受的差异。",

    machineryEfficientlyTitle: "机器运行更高效",
    machineryEfficientlyText:
      "OptCotton采用智能算法来减少停机问题，从而提高纺纱效率。",

    hviParametersControlledTitle: "在配棉过程中控制所有HVI参数",
    hviParametersControlledText:
      "系统可控制所有HVI参数，以及每家企业所要求的内部参数。",

    controlInventoryTitle: "全面控制库存、消耗和混合棉质量",
    controlInventoryText:
      "OptCotton可通过控制工具、报告和图表，全面控制库存的数量和质量，以及棉花的消耗。",
  },
};

export default translation;
