const translation = {
  buttons: {
    home: "Home",
    contact: "Contacto",
    about: "Acerca de",
    testItForFree: "Pruébalo gratis",
    howItWorks: "Cómo funciona esto",
    allBenefits: "Todos los beneficios",
    seeOurCustomerList: "Ver la lista de clientes",
    submit: "Mandar",
  },
};

export default translation;
