const translation = {
  privacyPolicy: {
    popupMessage: "本网站使用cookie，继续浏览即表示您同意使用cookie。",
    learnMore: "了解更多",
    accept: "接受",
    deny: "拒绝",
    title: "Cookie隐私政策",
    description:
      "本cookie隐私政策介绍了optcotton.com网站在您访问或与网站互动时如何使用cookie和类似技术。本政策还解释了您在使用cookie方面的权利，以及您如何控制自己的偏好。",
    consent: "同意",
    consentText:
      "使用optcotton.com网站，即表示您同意根据本cookie隐私政策使用cookie。如果您不同意使用cookie，我们建议您调整浏览器设置或停止使用optcotton.com网站。",
    cookiesType: "使用的cookie类型",
    cookiesTypeText: "optcotton.com网站可能使用以下类型的cookie：",
    cookiesType1:
      "基本cookie：这些cookie是网站基本功能所必需的，通过它们，您可以浏览网站并使用登录帐户等基本功能。",
    cookiesType2:
      "性能cookie：这些cookie收集访问者如何使用网站的信息，如访问的页面、在网站上花费的时间和遇到的错误等。这些信息用于提高网站的性能和可用性。",
    cookiesType3:
      "功能cookie：这些cookie可让网站记住您的偏好，如您选择的语言或您所在的地区等。这将在您访问网站时提供个性化体验。",
    cookiesType4:
      "广告cookie：我们可能允许第三方在optcotton.com网站上显示广告。这些cookie会根据您过去与网站的互动情况收集您的兴趣信息，以便广告商展示与您相关的广告。",
    cookiesControl: "Cookie的控制",
    cookiesControlText:
      "您有权控制和管理自己的cookie偏好。您可以调整浏览器设置来阻止或删除cookie，也可以设置对第三方cookie的偏好。请注意，如果阻止或删除cookie，optcotton.com网站的某些功能可能无法正常运行。",
    links: "与其他网站的链接",
    linksText:
      "optcotton.com网站可能包含其他网站的链接，这些网站有自己的隐私政策。我们建议您查看这些网站的隐私政策，因为我们无法控制其对cookie和隐私信息的操作。",
    policyChanges: "Cookie隐私政策的更改",
    policyChangesText:
      "我们保留随时更改cookie隐私政策的权利。任何政策更新均将在optcotton.com网站上发布后立即生效。",
    questions:
      "如果您对我们的cookie隐私政策有任何问题或疑虑，请通过optcotton.com网站上提供的方式与我们联系。",
    implementation: "生效日期：2023年6月23日。",
  },
};
export default translation;
