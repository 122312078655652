const translation = {
  buttons: {
    home: "Home",
    contact: "Contact",
    about: "About",
    testItForFree: "Test it for free",
    howItWorks: "How does this work",
    allBenefits: "All benefits",
    seeOurCustomerList: "See the customer list",
    submit: "Submit",
  },
};

export default translation;
