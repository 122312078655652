const translation = {
  about: {
    aboutTitle: "About us",
    companyName: "WW Systems",
    aboutText:
      "is a leading company which develops software systems for the cotton industry. We are highly concerned about quality in the textile production. We are located in South Brazil, about 02-hour flight from São Paulo. Our team is composed of highly experienced software engineers with international expertise in the spinning process.",
    originTitle: "Origin",
    originText1:
      "Our history began in 2004 with the creation of the company by the current CEO, Jaison Werlich, who already developed software for the textile sector and identified the need for a tool that would make the process of making cotton blends efficient and safe. From then on, WW Sistemas was created, which, after internationalization and in 2015, changed its name to WW Systems",
    originText2:
      "With global sales and service organization, WW Systems maintains its continuous growth in sales via constant prospection mainly in the Asian market. Strategic partnerships are also a constant in the search for new horizons in the global market. Currently, in addition to several agents operating in several countries, WW Systems has conquered important strategic partnerships, such as ",

    rieterName: "Rieter",
    rieterCountry: "(Switzerland)",
    imgDescription: "WW Systems headquarter",
  },
};

export default translation;
