export const markers = {
  Paraguay: { name: "paraguai", color: "#566575" },
  Mexico: { name: "mexico", color: "#566575" },
  Brazil: { name: "brasil", color: "#566575" },
  India: { name: "india", color: "#566575" },
  Argentina: { name: "argentina", color: "#566575" },
  Pakistan: { name: "paquistao", color: "#566575" },
  Thailand: { name: "tailandia", color: "#566575" },
  /* Bangladesh: { name: "bangladesh", color: "#566575" }, */
};
