const translation = {
  howItWorks: {
    howItWorksTitle: "How it works",
    howItWorksText1:
      "é um software para elaboração e controle de mezclas de algodão que controla todos os parâmetros de HVI, além dos parâmetros internos criados por cada empresa com o máximo de precisão e sem a necessidade de separar o inventário em classes.",
    howItWorksText2:
      "Su objetivo principal es la eliminación de variaciones de calidad entre mezclas para que el hilo mantenga sus estándares de calidad. El software gestiona el inventario desde que llegan las balas de algodón hasta su consumo en la línea de apertura.",
    howItWorksText3:
      "Planificado y desarrollado por un equipo con conocimientos tanto en tecnología como en procesos de mezcla, el software ofrece una gama de recursos que brindan un control total sobre el proceso de producción de hilo.",
    steps:
      "Brevemente, estos son los pasos en el proceso de creación de mezcla:",
    step1Title: "En primer lugar, la definición de calidad!",
    step1Text:
      "El proceso de mezcla comienza con la planificación. Basado tanto en la calidad del inventario disponible como en la calidad de la última mezcla, el software puede calcular la calidad aproximada para la próxima disposición con el",
    step1TextBold: "simple clic de un botón.",
    step2Title: "Entonces, ¡vamos a sacar unas pacas del inventario!",
    step2Text:
      "En el segundo paso del proceso, el sistema define cuántas balas se necesitarán de cada lote para crear una mezcla previa. En el almacén, los fardos se separan físicamente para el proceso de selección según la cantidad definida para cada lote.",
    step2TextBold: "Se pueden recoger las pacas más accesibles.",
    step3Title: "Pasemos a la selección de fardos para la mezcla.",
    step3Text:
      "OptCotton, a través de su algoritmo, analiza la información de cada paca recogida en la etapa de pre-mezcla y realiza la selección para que la calidad media de lay-down sea lo más cercana posible a la calidad prevista en la etapa inicial. Las pacas seleccionadas se clasifican aún más para que estén dispuestas uniformemente en la línea de apertura.",
    step4Title: "Proceso planificado, ejecutado y resultado alcanzado.",
    step4Text:
      "Como resultado del proceso de mezcla planificado desde la calidad del inventario con el uso de inteligencia artificial, tenemos mezclas con calidad uniforme y estable.",
  },
};

export default translation;
