import React from "react";
import "../../main.css";
import "./style.css";
import WWLogo from "./../../images/ww-logo-white.png";
import Rieter from "./../../images/rieter-logo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  function handleButtonClick(path) {
    navigate(path);
  }

  return (
    <footer className="footer">
      <div id="partner-logos-wrapper" className="footer-itens">
        <div>
          <div className="dev-text">{t("footer.developed")}</div>
          <a href="https://www.facebook.com/wwsystemsbrazil">
            <img id="ww-logo" src={WWLogo}></img>
          </a>
        </div>
        <div>
          <div className="dev-text">{t("footer.strategicPartner")}</div>
          <a href="https://www.rieter.com/">
            <img id="rieter-logo" src={Rieter}></img>
          </a>
          <div className="dev-text">
            作为股东，立达支持WW Systems致力于开发 革命性创新的棉包管理系统 A.
            配备“OptCotton”系统的布线建立了 棉包管理标杆并达到新水平
            在质量和效率方面，立达为塑造未来而感到自豪。 与 WW Systems
          </div>
        </div>
      </div>
      <div id="legal-info" className="footer-itens">
        <div style={{ marginBottom: 50 }}>
          {t("footer.footerText")}
          <br></br>
          <span style={{ marginTop: 20 }}>{t("footer.patent")}</span>
        </div>
        <div id="menu-footer-wrapper">
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/")}
          >
            {t("buttons.home")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/about")}
          >
            {t("buttons.about")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/test-it-for-free")}
          >
            {t("buttons.testItForFree")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/contact")}
          >
            {t("buttons.contact")}
          </button>
        </div>
      </div>
    </footer>
  );
}
