import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import SaIndia from "./../../images/customers/saa_india.png";
import Alpina from "./../../images/customers/alpina-textil.png";
import GrupoMiro from "./../../images/customers/grupo-miro.png";
import FioPuro from "./../../images/customers/fio-puro.jpg";
import Sapphire from "./../../images/customers/sapphire.png";
import Ritex from "./../../images/customers/Ritex.jpg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";

export default function CustomersSlider() {
  return (
    <>
      <Swiper
        speed={500}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          550: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Navigation, Autoplay]}
      >
        <div
          slot="container-start"
          className="benefits-wrapper"
          data-swiper-parallax="-23%"
        ></div>
        <SwiperSlide>
          <div id="customers-wrapper" data-swiper-parallax="-300">
            <div className="customers-item">
              <a href="https://www.alpinatextil.net/" target="blank">
                <img className="customers-item-logo" src={Alpina}></img>
              </a>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div id="customers-wrapper" data-swiper-parallax="-300">
            <div className="customers-item">
              <a href="https://sapphiretextiles.com.pk/" target="blank">
                <img className="customers-item-logo" src={Sapphire}></img>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div id="customers-wrapper" data-swiper-parallax="-300">
            <div className="customers-item">
              <a href="https://www.grupomiro.com/" target="blank">
                <img className="customers-item-logo" src={GrupoMiro}></img>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div id="customers-wrapper" data-swiper-parallax="-300">
            <div className="customers-item">
              <a href="https://grupokyly.com.br/pt_BR/fiacao" target="blank">
                <img className="customers-item-logo" src={FioPuro}></img>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div id="customers-wrapper" data-swiper-parallax="-300">
            <div className="customers-item">
              <a href="https://ritexweb.com.ar/" target="blank">
                <img className="customers-item-logo" src={Ritex}></img>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div id="customers-wrapper" data-swiper-parallax="-300">
            <div className="customers-item">
              <a href="http://www.saaindia.com/" target="blank">
                <img className="customers-item-logo" src={SaIndia}></img>
              </a>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
