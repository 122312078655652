const translation = {
  form: {
    selectOption: "Select a option",
    question1: "Is your company a spinning mill?",
    question1Options: {
      option1: "Yes",
      option2: "No",
    },

    question2: "What is your location?",
    question3: "What is your email?",
    question4: "What is your direct phone contact?",

    question5:
      "Does your Spinning Mill have an HVI machine? Such as Uster’s? Loepfe’s? Premier’s? Others?",
    question5Options: {
      option1: "Yes, my spinning mill has same sort of HVI machine",
      option2: "No, We don't have anything similar.",
      option3: "Although our spinning mill does have, We don't use it.",
      option4: " No, but We intend to purchase it in a near future",
    },

    question6: "What is your mill’s monthly cotton consumption in tons?",
    question7:
      "How many production lines/blow rooms (exclusive for cotton) does your spinning mill have?",

    question8: "Is there a quality manager working for your spinning mill?",
    question8Options: { option1: "Yes, there is", option2: "No, there isn't" },

    question9: "Have the purchased/current cotton bales being 100% tested?",
    question9Options: {
      option1: "Yes, 100% of the bales have been tested",
      option2: "No, but about 50% of the bales have been tested",
      option3: "No, but about 30% of the bales have been tested",
      option4: "No, but 10% of the bales have been tested",
      option5:
        " No, Although nothing has been tested, We plan to strat testing them shortly.",
    },

    question10: "How are the bales stacked in the warehouse currently?",
    question10Options: {
      option1: "Lot wise",
      option2: "Container wise",
      option3: "Lorry wise",
      option4: "Some other methodology",
    },

    question11:
      "In order to proceed with the trial, we need a quality manager available to be trained by us. Is there any available for training?",
    question11Options: {
      option1: "Yes, there is one.",
      option2: "No, there isn't one",
    },

    question12: "When would you like us to begin the trial?",
    question13:
      "Please write down your complete name together with the address of the Spinning Mill you'd like to run the trial version",
  },
};
export default translation;
