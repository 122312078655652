const translation = {
  howItWorks: {
    howItWorksTitle: "如何运行",
    howItWorksText1:
      "是一款用于设计和控制配棉分层的软件，除了控制所有HVI参数外，还可以包括各企业创建的内部参数，精度极高，且无需对库存进行分类。",
    howItWorksText2:
      "该软件的主要目的是消除棉层之间的品质差异，从而保证出纱质量。软件从棉包入库到棉包开包实现全过程管理。",
    howItWorksText3:
      "该软件由一个既懂技术，又懂配棉工艺的团队进行规划和开发，提供一系列资源，对纱线生产过程进行全面控制。",
    steps: "该软件的配棉主要步骤包括：",
    step1Title: "首先，定义品质",
    step1Text:
      "配棉流程从规划开始。根据现有库存质量和前一批质量，只需点击一下按键，软件就能计算出下一批的大致质量。",
    step1TextBold: "",
    step2Title: "然后，从库存中调取棉包！",
    step2Text:
      "在流程的第二步，系统会定义每个批次需要多少棉包，来创建预混层。在仓库中，根据为每批货物定义的数量，将棉包进行物理分离，以便进行选择。最容易取到的棉包可以被选中。",
    step2TextBold: "",
    step3Title: "选择用于配棉的棉包。",
    step3Text:
      "OptCotton通过算法，对预混阶段拾取的每个棉包信息进行分析并做出选择，使配棉的平均质量尽可能接近初始的设计质量。被选中的棉包将进一步分拣，使其均匀地排列在开包机上。",
    step4Title: "执行预设流程确保结果",
    step4Text:
      "通过人工智能技术，根据库存性质规划配棉过程，得到质量均匀稳定的棉层。",
  },
};

export default translation;
