//buttons
import buttonsPT from "./pages/components/buttons/pt";
import buttonsEN from "./pages/components/buttons/en";
import buttonsES from "./pages/components/buttons/es";
import buttonsCH from "./pages/components/buttons/ch";

//footer

import footerPT from "./pages/components/footer/pt";
import footerEN from "./pages/components/footer/en";
import footerES from "./pages/components/footer/es";
import footerCH from "./pages/components/footer/ch";

//home page
import homePT from "./pages/home/pt";
import homeES from "./pages/home/es";
import homeEN from "./pages/home/en";
import homeCH from "./pages/home/ch";

//about page
import aboutPT from "./pages/about/pt";
import aboutES from "./pages/about/es";
import aboutEN from "./pages/about/en";
import aboutCH from "./pages/about/ch";

//benefits page

import benefitsPT from "./pages/benefits/pt";
import benefitsES from "./pages/benefits/es";
import benefitsEN from "./pages/benefits/en";
import benefitsCH from "./pages/benefits/ch";

//contact page
import contactPT from "./pages/contact/pt";
import contactES from "./pages/contact/es";
import contactEN from "./pages/contact/en";
import contactCH from "./pages/contact/ch";

//how it works page
import howItWorksPT from "./pages/howItWorks/pt";
import howItWorksES from "./pages/howItWorks/es";
import howItWorksEN from "./pages/howItWorks/en";
import howItWorksCH from "./pages/howItWorks/ch";

//customers page
import customersPT from "./pages/customers/pt";
import customersES from "./pages/customers/es";
import customersEN from "./pages/customers/en";
import customersCH from "./pages/customers/ch";

//test it for free
import testItForFreePT from "./pages/testItForFree/pt";
import testItForFreeES from "./pages/testItForFree/es";
import testItForFreeEN from "./pages/testItForFree/en";
import testItForFreeCH from "./pages/testItForFree/ch";

//test it for free form
import formPT from "./pages/testItForFree/form/pt";
import formES from "./pages/testItForFree/form/es";
import formEN from "./pages/testItForFree/form/en";
import formCH from "./pages/testItForFree/form/ch";

//Privacy policy
import privacyPolicyPT from "./pages/privacyPolicy/pt";
import privacyPolicyES from "./pages/privacyPolicy/es";
import privacyPolicyEN from "./pages/privacyPolicy/en";
import privacyPolicyCH from "./pages/privacyPolicy/ch";

const messages = {
  //english
  en: {
    translation: {
      ...buttonsEN,
      ...homeEN,
      ...footerEN,
      ...aboutEN,
      ...benefitsEN,
      ...contactEN,
      ...howItWorksEN,
      ...customersEN,
      ...testItForFreeEN,
      ...formEN,
      ...privacyPolicyEN,
    },
  },

  //portuguese
  pt: {
    translation: {
      ...buttonsPT,
      ...homePT,
      ...footerPT,
      ...aboutPT,
      ...benefitsPT,
      ...contactPT,
      ...howItWorksPT,
      ...customersPT,
      ...testItForFreePT,
      ...formPT,
      ...privacyPolicyPT,
    },
  },

  //spanish
  es: {
    translation: {
      ...buttonsES,
      ...homeES,
      ...footerES,
      ...aboutES,
      ...benefitsES,
      ...contactES,
      ...howItWorksES,
      ...customersES,
      ...testItForFreeES,
      ...formES,
      ...privacyPolicyES,
    },
  },

  //mandarim
  zh: {
    translation: {
      ...buttonsCH,
      ...homeCH,
      ...footerCH,
      ...aboutCH,
      ...benefitsCH,
      ...contactCH,
      ...howItWorksCH,
      ...customersCH,
      ...testItForFreeCH,
      ...formCH,
      ...privacyPolicyCH,
    },
  },
};

export { messages };
