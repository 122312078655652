const translation = {
  customers: {
    customersTitle: "Nossos clientes",
    customersText1:
      "A versatilidade do OptCotton significa que ele pode ser usado em qualquer processo de mistura em qualquer lugar do mundo.",
    customersText2:
      "Nosso software atualmente está presente em 11 países no mundo: ",
    customersCountry:
      "Argentina, Bangladesh, Brasil, Índia, Indonésia, México, Paquistão, Paraguai, Tailândia, Turquia e Uzbequistão.",
    customersText3:
      "Passe o mouse sobre os países destacados para ver algumas das principais empresas têxteis que entraram no futuro usando Optcotton.",

    customerText4:
      "Veja abaixo os as empresa de cada país que optaram em dar um passo para o futuro ao escolher usar o OptCotton:",
  },
};
export default translation;
