const translation = {
  customers: {
    customersTitle: "Our customers",
    customersText1:
      "OptCotton's versatility means it can be used in any blending process anywhere in the world.",
    customersText2: "Our software is currently present in 11 countries:",
    customersCountry:
      "Argentina, Bangladesh, Brazil, India, Indonesia, Mexico, Pakistan, Paraguay, Thayland, Türkiye and Uzbekistan.",
    customersText3:
      "Mouse over highlighted countries to see some of the important major textile companies which stepped into the future using Optcotton.",
    customersText4:
      "See below the companies in each country that chose to take a step into the future by choosing to use OptCotton:",
  },
};
export default translation;
