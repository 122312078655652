const translation = {
  benefits: {
    benefitsTitle: "Os benefícios de usar OptCotton",
    benefitsText1:
      "A inteligência artificial está cada vez mais presente no dia a dia das pessoas e empresas, e a",

    benefitsTextBold: "OptCotton traz a IA para o processo ",
    benefitsText2:
      "de mistura com o objetivo de proporcionar aos clientes segurança, produtividade e qualidade na produção",

    seeMainBenefits: "Veja os principais benefícios:",

    noNeedSepareteTitle: "Não é necessário separar o estoque por classes",
    noNeedSepareteText:
      "Esta é a metodologia mais inovadora de gerenciamento de fardos até agora! A categorização de inventário faz parte do passado!",

    shadeVariationTitle: "Controle eficiente de variações de tonalidade",
    shadeVariationText:
      "Tanto o uso de algoritmos sofisticados para deposição de algodão quanto a redução da variação na qualidade eliminam esse risco.",

    uniformsLaydownsTitle: "Misturas uniformes",
    uniformsLaydownsText:
      "A fim de evitar variações nos padrões de qualidade, a OptCotton utiliza tanto a qualidade do estoque quanto a das misturas anteriores, portanto não serão aceitas variações intoleráveis.",

    machineryEfficientlyTitle: "Máquinas funcionam com mais eficiência",
    machineryEfficientlyText:
      "O OptCotton usa algoritmos inteligentes para manter as máquinas funcionando com menos interrupções, consequentemente aumentando a eficiência do processo de fiação.",

    hviParametersControlledTitle:
      "Todos os parâmetros HVI controlados na mistura",
    hviParametersControlledText:
      "O sistema controla todos os parâmetros do HVI, além dos parâmetros internos exigidos em cada empresa.",

    controlInventoryTitle:
      "Controle total de estoque, consumo e qualidade das misturas",
    controlInventoryText:
      "O OptCotton permite, através de ferramentas de controle, relatórios e gráficos, o controle total da quantidade e qualidade do estoque, além do consumo de algodão.",
  },
};

export default translation;
