import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <>
      <div id="page-wrapper">
        <div className="topics">{t("privacyPolicy.title")}</div>

        <div className="div-content">{t("privacyPolicy.description")}</div>

        <div className="topics">1. {t("privacyPolicy.consent")}</div>
        <div className="div-content">{t("privacyPolicy.consentText")}</div>

        <div className="topics">2. {t("privacyPolicy.cookiesType")}</div>
        <div className="div-content">{t("privacyPolicy.cookiesTypeText")}</div>
        <div className="div-content">a) {t("privacyPolicy.cookiesType1")}</div>
        <div className="div-content">b) {t("privacyPolicy.cookiesType2")}</div>
        <div className="div-content">c) {t("privacyPolicy.cookiesType3")}</div>
        <div className="div-content">d) {t("privacyPolicy.cookiesType4")}</div>

        <div className="topics">3. {t("privacyPolicy.cookiesControl")}</div>
        <div className="div-content">
          {t("privacyPolicy.cookiesControlText")}
        </div>

        <div className="topics">4. {t("privacyPolicy.links")}</div>
        <div className="div-content">{t("privacyPolicy.linksText")}</div>
        <div className="topics">5. {t("privacyPolicy.policyChanges")}</div>
        <div className="div-content">
          {t("privacyPolicy.policyChangesText")}
        </div>

        <div className="div-content">{t("privacyPolicy.questions")}</div>
        <div className="div-content">{t("privacyPolicy.implementation")}</div>
      </div>
    </>
  );
}
