const translation = {
  privacyPolicy: {
    popupMessage:
      "Esse website utiliza cookies. Ao continuar navegando, você concorda com a utilização de cookies.",
    learnMore: "Saiba mais",
    accept: "Aceitar",
    deny: "Negar",
    title: "Política de Privacidade de Cookies",
    description:
      "Esta Política de Privacidade de Cookies descreve como o site optcotton.com utiliza cookies e tecnologias semelhantes quando você visita ou interage com o site. Esta política também explica quais são os seus direitos em relação ao uso de cookies e como você pode controlar suas preferências.",
    consent: "Consentimento",
    consentText:
      "Ao usar o site optcotton.com, você concorda com o uso de cookies de acordo com esta Política de Privacidade de Cookies. Se você não concorda com o uso de cookies, recomendamos que você ajuste as configurações do seu navegador ou pare de usar o site optcotton.com.",
    cookiesType: "Tipos de cookies utilizados",
    cookiesTypeText:
      "O site optcotton.com pode utilizar os seguintes tipos de cookies:",
    cookiesType1:
      " Cookies essenciais: São cookies necessários para o funcionamento básico do site. Eles permitem que você navegue pelo site e use recursos essenciais, como fazer login em uma conta.",
    cookiesType2:
      "Cookies de desempenho: Esses cookies coletam informações sobre como os visitantes usam o site, como as páginas visitadas, o tempo gasto no site e os erros encontrados. Essas informações são usadas para melhorar o desempenho e a usabilidade do site.",
    cookiesType3:
      "Cookies de funcionalidade: Esses cookies permitem que o site lembre suas preferências, como o idioma escolhido ou a região em que você está. Isso proporciona uma experiência personalizada durante sua visita ao site.",
    cookiesType4:
      "Cookies de publicidade: Podemos permitir que terceiros exibam anúncios no site optcotton.com. Esses cookies coletam informações sobre seus interesses com base em suas interações anteriores com o site, permitindo que os anunciantes exibam anúncios relevantes para você.",
    cookiesControl: "Controle de cookies",
    cookiesControlText:
      "Você tem o direito de controlar e gerenciar suas preferências de cookies. Você pode ajustar as configurações do seu navegador para bloquear ou excluir cookies, bem como configurar preferências para cookies de terceiros. Observe que ao bloquear ou excluir cookies, alguns recursos do site optcotton.com podem não funcionar corretamente.",
    links: "Links para outros sites",
    linksText:
      "O site optcotton.com pode conter links para outros sites que possuem suas próprias políticas de privacidade. Recomendamos que você revise as políticas de privacidade desses sites, pois não temos controle sobre as práticas de cookies e privacidade deles.",
    policyChanges: "Alterações na Política de Privacidade de Cookies",
    policyChangesText:
      "Reservamo-nos o direito de fazer alterações nesta Política de Privacidade de Cookies a qualquer momento. Quaisquer alterações entrarão em vigor imediatamente após a publicação da política atualizada no site optcotton.com.",
    questions:
      "Se você tiver alguma dúvida ou preocupação sobre nossa Política de Privacidade de Cookies, entre em contato conosco através dos detalhes de contato fornecidos no site optcotton.com.",
    implementation: "Data de entrada em vigor: 23/06/2023.",
  },
};
export default translation;
