const translation = {
  footer: {
    developed: "开发公司：",
    strategicPartner: "战略合作伙伴：",
    footerText: "所使用的方法、技术和智能受美国、巴西和瑞士版权法的保护。",
    patent: "专利申请号：US 10.815.589",
  },
};

export default translation;
