const translation = {
  buttons: {
    home: "主页",
    contact: "联系我们",
    about: "关于我们",
    testItForFree: "免费试用",
    howItWorks: "如何运行",
    allBenefits: "所有优势",
    seeOurCustomerList: "查看客户名单",
    submit: "提交",
  },
};

export default translation;
