import React from "react";
import VectorMap, {
  ControlBar,
  Layer,
  Tooltip,
} from "devextreme-react/vector-map";
import { markers } from "./data";
import * as mapsData from "devextreme/dist/js/vectormap-data/world.js";
import ToolTipComponent from "./tooltipTemplate";
//brasil
import alpina from "../../images/customers/alpina-textil.png";
import cooperfibra from "../../images/customers/cooperfibra.png";
import braxtex from "../../images/customers/Braxtex.jpg";
import coteminas from "../../images/customers/Coteminas.jpg";
import dohler from "../../images/customers/Dohler.png";
import estamparia from "../../images/customers/Estamparia.jpg";
import fabril from "../../images/customers/Fabril Mascarenhas.jpg";
import itabaiana from "../../images/customers/fiacaoitabaiana.png";
import fiasul from "../../images/customers/Fiasul.JPG";
import kyly from "../../images/customers/fio-puro.jpg";
import nb from "../../images/customers/NB.png";
import peripan from "../../images/customers/Peripan.png";
import saogeraldo from "../../images/customers/São Geraldo.png";
import tbm from "../../images/customers/TBM.png";
import tear from "../../images/customers/TearTextil.jpg";
import willrich from "../../images/customers/willrich.jpeg";
import Kurashiki from "../../images/customers/Kurashiki.png";
import PeDeSerra from "../../images/customers/Pe-de-serra.gif";
import PHB from "../../images/customers/PHB.png";
import TextilUniao from "../../images/customers/Textil-uniao.png";
import universal from "../../images/customers/universal.png";

//paraguai
import hoahi from "../../images/customers/Hoahi-Logo-01.png";

//paquistão
import tata from "../../images/customers/tata-pakistan.jpg";
import premium from "../../images/customers/Premium.png";
import indus from "../../images/customers/Indus-group.png";
import sapphire from "../../images/customers/sapphire.png";
import yunus from "../../images/customers/Yunus.png";
import mahmood from "../../images/customers/mahmood-group.png";

//mexico
import miro from "../../images/customers/grupo-miro.png";
import zagis from "../../images/customers/zagis.png";

//argentina
import ritex from "../../images/customers/Ritex.jpg";

//india
import ssa from "../../images/customers/saa_india.png";

//tailandia
import thai from "../../images/customers/TID-group.jpg";

//bangladesh

/* import nomam from "../../images/customers/Noman-group.png";
import saad from "../../images/customers/SAAD-group.jpg";
 */

import { useTranslation } from "react-i18next";

export default function CostumersMap() {
  const { t } = useTranslation();
  const bounds = [-180, 85, 180, -60];
  const brasil = [
    alpina,
    cooperfibra,
    braxtex,
    coteminas,
    dohler,
    estamparia,
    fabril,
    itabaiana,
    fiasul,
    kyly,
    nb,
    peripan,
    saogeraldo,
    tbm,
    tear,
    willrich,
    Kurashiki,
    PeDeSerra,
    PHB,
    TextilUniao,
    universal,
  ];
  const india = [ssa];
  const tailandia = [thai];
  const argentina = [ritex];
  const paraguai = [hoahi];
  const mexico = [miro, zagis];
  const paquistao = [tata, premium, indus, sapphire, yunus, mahmood];
  /*   const bangladesh = [saad, nomam]; */

  function customizeLayer(elements) {
    elements.forEach((element) => {
      const country = markers[element.attribute("name")];
      if (country) {
        element.applySettings({
          color: country.color,
          hoveredColor: "#2c3b49",
          selectedColor: "#566575",
        });
      }
    });
  }
  return (
    <>
      <div id="page-wrapper">
        <div className="page-title">{t("customers.customersTitle")}</div>
        <p>{t("customers.customersText1")}</p>
        <p>
          {t("customers.customersText2")}{" "}
          <span className="bold">{t("customers.customersCountry")}</span>
        </p>

        <p id="mouse-over-p">{t("customers.customersText3")}</p>
        <p id="see-bellow-p">{t("customers.customersText4")}</p>
        <div id="customers-map-wrapper">
          <VectorMap
            id="vector-map"
            bounds={bounds}
            wheelEnabled={false}
            zoomingEnabled={true}
            zoomFactor={2.5}
            panningEnabled={true}
            center={[-5.566686039757849, -2.5374095097389184]}
          >
            <ControlBar enabled={false}></ControlBar>
            <Layer
              dataSource={mapsData.world}
              customize={customizeLayer}
            ></Layer>
            <Tooltip enabled={true} contentRender={ToolTipComponent}></Tooltip>
          </VectorMap>
        </div>
        <div id="customers-list-wrapper">
          <div className="customers-list-country">Brazil</div>
          <div className="customers-list-logo-wrapper">
            {brasil.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div>
          <div className="customers-list-country">Argentina</div>
          <div className="customers-list-logo-wrapper">
            {argentina.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div>
          <div className="customers-list-country">Paraguay</div>
          <div className="customers-list-logo-wrapper">
            {paraguai.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div>
          <div className="customers-list-country">Mexico</div>
          <div className="customers-list-logo-wrapper">
            {mexico.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div>
          <div className="customers-list-country">Pakistan</div>
          <div className="customers-list-logo-wrapper">
            {paquistao.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div>
          <div className="customers-list-country">India</div>
          <div className="customers-list-logo-wrapper">
            {india.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div>
          {/*    <div className="customers-list-country">Bangladesh</div>
          <div className="customers-list-logo-wrapper">
            {bangladesh.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div> */}
          <div className="customers-list-country">Thayland</div>
          <div className="customers-list-logo-wrapper">
            {tailandia.map((image) => (
              <div>
                <img
                  className="customers-list-logo"
                  src={image}
                  alt="Descrição da imagem"
                  key={image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
