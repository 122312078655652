import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Parallax, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function BenefitsSlider() {
  const { t } = useTranslation();
  return (
    <>
      <Swiper
        speed={500}
        parallax={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        modules={[Parallax, Pagination, Navigation, Autoplay]}
      >
        <div
          slot="container-start"
          className="benefits-wrapper"
          data-swiper-parallax="-23%"
        ></div>
        <SwiperSlide>
          <div className="benefits-title" data-swiper-parallax="-300">
            {t("home.benefitSlider.benefitTitle1")}
          </div>
          <div className="benefits-text" data-swiper-parallax="-100">
            <p>{t("home.benefitSlider.benefitText1")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="benefits-title" data-swiper-parallax="-300">
            {t("home.benefitSlider.benefitTitle2")}
          </div>
          <div className="benefits-text" data-swiper-parallax="-100">
            <p>{t("home.benefitSlider.benefitText2")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="benefits-title" data-swiper-parallax="-300">
            {t("home.benefitSlider.benefitTitle3")}
          </div>
          <div className="benefits-text" data-swiper-parallax="-100">
            <p>{t("home.benefitSlider.benefitText3")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="benefits-title" data-swiper-parallax="-300">
            {t("home.benefitSlider.benefitTitle4")}
          </div>
          <div className="benefits-text" data-swiper-parallax="-100">
            <p>{t("home.benefitSlider.benefitText4")}</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
