const translation = {
  form: {
    selectOption: "单选",
    question1: "贵公司是纺纱厂吗？",
    question1Options: {
      option1: "是",
      option2: "否",
    },

    question2: "贵公司位于何处？",
    question3: "您的电子邮件地址是什么？",
    question4: "您的直线电话是多少？",

    question5: "贵纺纱厂有HVI机器吗？比如Uster？Loepfe？Premier？其他？",
    question5Options: {
      option1: "是的，我的纺纱厂有相同类型的HVI机器",
      option2: "不，我们没有类似的设备。",
      option3: "虽然我们的纺纱厂有，但我们没有使用过。",
      option4: "没有，但我们打算在不久的将来采购。",
    },

    question6: "贵厂每月的棉花消耗量是多少吨？",
    question7: "贵纺纱厂有多少条生产线/清花间（棉花专用）？",

    question8: "贵纺纱厂是否有质量经理？",
    question8Options: { option1: "有", option2: "无" },

    question9: "外购/现有棉包是否100%经过检验？",
    question9Options: {
      option1: "是，100%的棉包都经过检验",
      option2: "否，但约50%的棉包经过检验",
      option3: "否，但约30%的棉包经过检验",
      option4: "否，但有10%的棉包经过检验",
      option5: "否，尽管没有进行任何检验，但我们计划很快开始检验。",
    },

    question10: "目前仓库里的棉包是如何堆放的？",
    question10Options: {
      option1: "按批次",
      option2: "按集装箱",
      option3: "按车次",
      option4: "其他方法",
    },

    question11:
      "为了进行试用，我们需要一名质量经理接受我们的培训。是否有人可接受培训？",
    question11Options: {
      option1: "是，有一位。",
      option2: "无。",
    },

    question12: "您希望何时开始试用？",
    question13: "请留下您的全名，以及希望运行试用版的纺纱厂地址",
  },
};
export default translation;
