const translation = {
  about: {
    aboutTitle: "关于我们",
    companyName: "WW Systems",
    aboutText:
      "是一家为棉纺行业开发软件系统的领先企业，高度关注纺织品生产的质量。公司总部位于巴西南部，距离圣保罗市约2小时的飞行时间。团队中的软件工程师不仅专业经验丰富，而且熟悉各国的纺纱工艺。",
    originTitle: "起源",
    originText1:
      "我们的公司于2004年由现任首席执行官Jaison Werlich创立。当时，他已经在为纺织行业开发软件，发现配棉工作亟需一款提高其效率和安全性的工具。正是在这种背景下，WW Sistemas应运而生，在实现国际化后，于2015年更名为WW Systems。",
    originText2:
      "凭借全球销售和服务网络，WW Systems通过不断开拓亚洲市场，保持了销售额的持续增长。搭建战略合作伙伴关系也是公司全球市场拓展中的一种常态。目前，除了在多个国家有业务代理商外，WW Systems还开发了重要的战略合作伙伴，例如 ",

    rieterName: "立达。",
    rieterCountry: "(瑞士的)",
    imgDescription: "WW Systems 总部",
  },
};

export default translation;
