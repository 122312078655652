import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function Benefits() {
  const { t } = useTranslation();
  return (
    <div id="benefits-wrapper">
      <div id="benefits-filter">
        <div id="page-wrapper">
          <div id="benefits-text-wrapper">
            <div className="page-title">{t("benefits.benefitsTitle")}</div>
            <p>
              {t("benefits.benefitsText1")}
              <span className="bold">
                {" "}
                {t("benefits.benefitsTextBold")}
              </span>{" "}
              {t("benefits.benefitsText2")}
            </p>
            <p>{t("benefits.seeMainBenefits")}</p>

            <p>
              <div className="topics">{t("benefits.noNeedSepareteTitle")}</div>
              <div>{t("benefits.noNeedSepareteText")}</div>
            </p>
            <p>
              <div className="topics">{t("benefits.shadeVariationTitle")}</div>
              <div>{t("benefits.shadeVariationText")}</div>
            </p>
            <p>
              <div className="topics">
                {t("benefits.uniformsLaydownsTitle")}
              </div>
              <div>{t("benefits.uniformsLaydownsText")}</div>
            </p>
            <p>
              <div className="topics">
                {t("benefits.machineryEfficientlyTitle")}
              </div>
              <div>{t("benefits.machineryEfficientlyText")}</div>
            </p>
            <p>
              <div className="topics">
                {t("benefits.hviParametersControlledTitle")}
              </div>
              <div>{t("benefits.hviParametersControlledText")}</div>
            </p>
            <p>
              <div className="topics">
                {t("benefits.controlInventoryTitle")}
              </div>
              <div>{t("benefits.controlInventoryText")}</div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
