const translation = {
  footer: {
    developed: "Desarrollado por:",
    strategicPartner: "Socio estratégico: ",
    footerText:
      "La metodología, la tecnología y la inteligencia están protegidas por leyes de derechos de autor tanto en EE. UU. como en Brasil y Suiza.",
    patent: "Solicitud de patente US 10.815.589,",
  },
};

export default translation;
