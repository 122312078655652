import React from "react";
import "./style.css";
import OptAgents from "./../../images/opt-agent.png";
import OptPhone from "./../../images/opt-phone.png";
import OptWhats from "./../../images/opt-whats.png";
import OptMail from "./../../images/opt-mail.jpg";
import OptLocal from "./../../images/opt-local.png";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  return (
    <div id="page-wrapper">
      <div className="page-title">{t("contact.contactTitle")}</div>
      <p>{t("contact.contactText")}</p>
      <div className="flex">
        <div className="contact-section">
          <div className="contact-tittle">
            <img className="contact-icons" src={OptAgents}></img>
            <div className="topics">{t("contact.findAgents")}</div>
          </div>
          <div className="contact-agent">
            <div>ASSL Textile</div>
            <span className="agent-location">(Bangladesh)</span>
            <div>+880 1671 791520 / +880 1777 701756</div>
          </div>
          <div className="contact-agent">
            <div>J V Texpro</div>
            <span className="agent-location">(India) </span>
            <div>+91 99147 04530</div>
          </div>
          <div className="contact-agent">
            <div>Soham</div>
            <span className="agent-location">(India)</span>
            <div>+91 93246 87021</div>
          </div>
          <div className="contact-agent">
            <div>Spinwell</div>
            <span className="agent-location">(India) </span>
            <div>+91 98654 45633</div>
          </div>
          <div className="contact-agent">
            <div>Glanz Indonesia</div>
            <span className="agent-location">(Indonesia)</span>
            <div>+62 813 2220 2007</div>
          </div>
          <div className="contact-agent">
            <div>DHM COMERCIAL LTDA</div>
            <span className="agent-location">(Iran)</span>
            <div>+98 91 2344 2853 / +55 48 988786092</div>
          </div>

          <div className="contact-agent">
            <div>Technical Textile</div>
            <span className="agent-location">(Pakistan)</span>
            <div>+92 300 8348702</div>
          </div>
          <div className="contact-agent">
            <div>OZDAG</div>
            <span className="agent-location">(Turkey)</span>
            <div>+90 532 200 24 32</div>
          </div>
          <div className="contact-agent">
            <div>Temsam</div>
            <span className="agent-location">(Uzbekistan)</span>
            <div>+998 93 383 09 70</div>
          </div>
        </div>
        <div className="contact-section">
          <div className="contact-tittle">
            <img className="contact-icons" src={OptPhone}></img>
            <div className="topics">{t("contact.call")}</div>
          </div>
          <div className="contact-item">
            <div className="bold">{t("contact.english")}</div>
            <div>+55 48 9 9655 7574</div>
            <div>{t("contact.englishWorkTime")}</div>
          </div>
          <div className="contact-item">
            <div className="bold">{t("contact.portugueseAndSpanish")}</div>
            <div>+55 48 3276 2029</div>
            <div>{t("contact.portugueseWorkTime")}</div>
          </div>
        </div>
        <div className="contact-section">
          <div className="contact-tittle">
            <img className="contact-icons" src={OptWhats}></img>
            <div className="topics">WhatsApp</div>
          </div>
          <div className="contact-item">
            <div>
              <a
                className="links"
                href="https://wa.me/5548996910179"
                target="blank"
              >
                +55 48 9 9655 7574
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="contact-section">
          <div className="contact-tittle">
            <img className="contact-icons" src={OptMail}></img>
            <div className="topics">{t("contact.email")}</div>
          </div>
          <div className="contact-item">
            <div className="bold">{t("contact.queries")}</div>
            <div>contact@optcotton.com</div>
          </div>
          <div className="contact-item">
            <div className="bold">{t("contact.support")}</div>
            <div>support@optcotton.com</div>
          </div>
        </div>
        <div className="contact-section">
          <div className="contact-tittle">
            <img className="contact-icons" src={OptLocal}></img>
            <div className="topics">{t("contact.location")}</div>
          </div>
          <div className="map-responsive">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.893550059272!2d-49.35529832484401!3d-27.68968477619268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952087ee62ee008d%3A0x51150fe2b99305b2!2sR.%20Padre%20Cristov%C3%A3o%20Arnald%2C%202283%20-%20Saltinho%2C%20Alfredo%20Wagner%20-%20SC%2C%2088450-000!5e0!3m2!1spt-BR!2sbr!4v1683316802585!5m2!1spt-BR!2sbr"
              width="600"
              height="350"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
