const translation = {
  home: {
    appTitle: "O mais eficiente software para misturas de algodão!",
    appDescription:
      "O OptCotton controla a qualidade das misturas, mantendo a qualidde estável, eliminando assim problemas como o barramento.",

    itmaInvitation: "Visite-nos na itma 2023",
    itmaDescription:
      "A maior exposição internacional de tecnologia têxtil e vestuário do mundo.",
    itmaLocale: "Estaremos localizados em ",
    itmaDate: "08 à 14 junho de 2023",

    howItWorks: "Como isso funciona?",
    howItWorksText:
      "O OptCotton gerencia todo o processo de mistura desde a entrada do algodão no estoque, gerando misturas com qualidade uniforme e trazendo segurança ao processo.",

    mixControlProcess: "Controle total no processo de mistura",
    mixControlProcessText:
      "Nosso sistema usa todos os parâmetros de qualidade de qualquer fonte HVI. O processo de mistura é planejado com precisão desde a qualidade do estoque disponível até a colocação dos fardos na linha de abertura.",
    mixControlProcessImg1: "Planejamento de qualidade",
    mixControlProcessImg2: "Separação dos fardos",
    mixControlProcessImg3: "Seleção dos fardos",
    mixControlProcessImg4: "Disposição dos fardos",

    benefitSlider: {
      benefitTitle1: "Controle de barramento eficiente",
      benefitText1:
        "Tanto o uso de algoritmos sofisticados para as misturas de algodão quanto a redução da variação na qualidade eliminam esse risco.",

      benefitTitle2: "Misturas uniformes",
      benefitText2:
        "A fim de evitar variações nos padrões de qualidade, a OptCotton utiliza tanto a qualidade do estoque quanto a qualidade das misturas anteriores, portanto não serão aceitas variações intoleráveis.",

      benefitTitle3: "Não é necessário separar o estoque",
      benefitText3:
        "Com uma metodologia única, não há necessidade de separar os fardos no depósito em classes de acordo com a qualidade.",

      benefitTitle4: "Todos os parâmetros HVI são controlados na mistura",
      benefitText4:
        "Controla todos os parâmetros HVI e também os parâmetros internos requeridos por cada empresa.",
    },

    aiTitle: "Inteligência artificial para a indústria têxtil",
    aiText:
      "O futuro da indústria têxtil depende da Inteligência Artificial para misturar algodão. Através de seu algoritmo, o sistema é capaz de analisar e selecionar os fardos para as deposições de forma a trazer estabilidade e segurança ao processo de mistura.",

    customersTitle: "Nossos clientes",
    customersText:
      "Atualmente estamos em 11 países localizados na América e na Ásia e já representamos 2% do algodão misturado do mundo. Veja abaixo alguns de nossos clientes.",
  },
};

export default translation;
