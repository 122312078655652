import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
const CookiePopup = () => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    setShowPopup(!cookiesAccepted);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", true);
    setShowPopup(false);
  };

  const handleDenyCookies = () => {
    localStorage.setItem("cookiesAccepted", false);
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div className="cookie-popup">
      <div className="cookie-content">
        <p>
          {t("privacyPolicy.popupMessage")}
          <a href="/privacyPolicy"> {t("privacyPolicy.learnMore")}</a>
        </p>

        <button onClick={handleAcceptCookies}>
          {t("privacyPolicy.accept")}
        </button>
        <button onClick={handleDenyCookies}>{t("privacyPolicy.deny")}</button>
      </div>
    </div>
  );
};

export default CookiePopup;
