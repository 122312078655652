const translation = {
  customers: {
    customersTitle: "我们的客户",
    customersText1: "OptCotton软件用途广泛，适用于世界任何地方的任何配棉流程。",
    customersText2: "我们的软件目前在11个国家使用：",
    customersCountry:
      "阿根廷、孟加拉、巴西、印度、印度尼西亚、墨西哥、巴基斯坦、巴拉圭、泰国、土耳其和乌兹别克斯坦。",
    customersText3:
      "将鼠标移至高亮显示的国家，可查看使用OptCotton软件迈向未来的重要大型纺织企业。",
    customersText4: "下面是不同国家选择使用OptCotton软件迈向未来的企业：",
  },
};
export default translation;
