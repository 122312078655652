const translation = {
  buttons: {
    home: "Início",
    contact: "Contato",
    about: "Sobre nós",
    testItForFree: "Teste grátis",
    howItWorks: "Como funciona",
    allBenefits: "Mais benefícios",
    seeOurCustomerList: "Veja a lista de clientes",
    submit: "Enviar",
  },
};

export default translation;
