const translation = {
  about: {
    aboutTitle: "Sobre nós",
    companyName: "WW Systems",
    aboutText:
      "É uma empresa líder no desenvolvimento de sistemas de software para a indústria algodoeira. Temos uma grande preocupação com a qualidade na produção têxtil. Estamos localizados no Sul do Brasil, cerca de 02 horas de voo de São Paulo. Nossa equipe é composta por engenheiros de software altamente experientes com experiência internacional no processo de fiação.",
    originTitle: "Origin",
    originText1:
      "Nossa história começou em 2004 com a criação da empresa pelo atual CEO, Jaison Werlich, que já desenvolvia softwares para o setor têxtil e identificou a necessidade de uma ferramenta que tornasse o processo de confecção de misturas de algodão eficiente e seguro. A partir daí, foi criada a WW Sistemas que, após a internacionalização e em 2015, mudou de nome para WW Systems",
    originText2:
      "Com expansão global de vendas e serviços, a WW Systems mantém seu crescimento contínuo em vendas via prospecção constante principalmente no mercado asiático. As parcerias estratégicas também são uma constante na busca por novos horizontes no mercado global. Atualmente, além de diversos agentes atuando em diversos países, a WW Systems conquistou importantes parcerias estratégicas, como a ",
    rieterName: "Rieter",
    rieterCountry: "(Suiça)",
    imgDescription: "Sede WW Systems",
  },
};

export default translation;
