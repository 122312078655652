import React from "react";
import WWOffice from "./../../images/ww-headquarter.png";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();
  return (
    <div id="page-wrapper">
      <div className="page-title">{t("about.aboutTitle")}</div>
      <p>
        <span className="bold">{t("about.companyName")}</span>{" "}
        {t("about.aboutText")}
      </p>
      <div className="flex">
        <div id="origin-text-wrapper">
          <div className="topics">{t("about.originTitle")}</div>
          <p>{t("about.originText1")}</p>
        </div>
        <div style={{ textAlign: "center" }}>
          <img id="ww-headquarter-img" src={WWOffice}></img>
          <div id="ww-headquarter-text">{t("about.imgDescription")}</div>
        </div>
      </div>
      <p>
        {t("about.originText2")}
        <a
          className="links"
          style={{ marginBottom: 0 }}
          href="https://rieter.com/"
          target="blank"
        >
          Rieter
        </a>{" "}
        {t("about.rieterCountry")}.
      </p>
    </div>
  );
}
