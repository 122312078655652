const translation = {
  privacyPolicy: {
    popupMessage:
      "This website uses cookies. By continuing to browse, you agree to the use of cookies.",
    learnMore: "Learn more",
    accept: "Accept",
    deny: "Deny",
    title: "Cookie Privacy Policy",
    description:
      "This Cookie Privacy Policy describes how the optcotton.com website uses cookies and similar technologies when you visit or interact with the website. This policy also explains what your rights are regarding the use of cookies and how you can control your preferences.",
    consent: "Consent",
    consentText:
      "By using the optcotton.com website, you consent to the use of cookies in accordance with this Cookie Privacy Policy. If you do not agree to the use of cookies, we recommend that you adjust your browser settings or stop using the optcotton.com website.",
    cookiesType: "Types of cookies used",
    cookiesTypeText:
      "The optcotton.com website may use the following types of cookies:",
    cookiesType1:
      "Essential cookies: These are cookies that are necessary for the basic functioning of the website. They allow you to navigate the site and use essential features such as logging into an account.",
    cookiesType2:
      "Performance cookies: These cookies collect information about how visitors use the website, such as pages visited, time spent on the website, and errors encountered. This information is used to improve the performance and usability of the website.",
    cookiesType3:
      "Functionality cookies: These cookies allow the website to remember your preferences, such as the language you choose or the region you are in. This provides a personalized experience during your visit to the site.",
    cookiesType4:
      "Advertising Cookies: We may allow third parties to display advertisements on the optcotton.com website. These cookies collect information about your interests based on your past interactions with the website, allowing advertisers to show ads that are relevant to you.",
    cookiesControl: "Cookies control",
    cookiesControlText:
      "You have the right to control and manage your cookie preferences. You can adjust your browser settings to block or delete cookies, as well as set preferences for third-party cookies. Please note that by blocking or deleting cookies, some features of the optcotton.com website may not function properly.",
    links: "Links to other websites",
    linksText:
      "The optcotton.com website may contain links to other websites that have their own privacy policies. We recommend that you review the privacy policies of these sites, as we have no control over their cookie and privacy practices.",
    policyChanges: "Changes to the Cookie Privacy Policy",
    policyChangesText:
      "We reserve the right to make changes to this Cookie Privacy Policy at any time. Any changes will be effective immediately upon posting of the updated policy on the optcotton.com website.",
    questions:
      "If you have any questions or concerns about our Cookie Privacy Policy, please contact us using the contact details provided on the optcotton.com website.",
    implementation: "Effective date: 06/23/2023.",
  },
};
export default translation;
