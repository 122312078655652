const translation = {
  contact: {
    contactTitle: "Entre em contato",
    contactText: "Como podemos ajudar você?",
    findAgents: "Encontre um dos nossos agentes",
    call: "Ligue para nós",
    english: "Inglês",
    englishWorkTime: "(GMT+1) das 13:00 às 17:00",
    portugueseAndSpanish: "Português / Espanhol",
    portugueseWorkTime: "(GMT-3) das 08:00 às 17:00",
    email: "Nos envie um email",
    queries: "Dúvidas",
    support: "Suporte",
    location: "Nossa localização",
  },
};

export default translation;
