import React from "react";
import "./style.css";
import SpinningMillForm from "./spinningMillForm";
import { useTranslation } from "react-i18next";

export default function TestItForFree() {
  const { t } = useTranslation();
  return (
    <div id="page-wrapper">
      <div className="page-title">{t("testItForFree.testItForFreeTitle")}</div>
      <p>
        {t("testItForFree.testItForFreeText1")}
        <span className="bold">
          {" "}
          {t("testItForFree.testItForFreeText1Bold")}
        </span>
      </p>

      <p>{t("testItForFree.testItForFreeText2")}</p>

      <p>{t("testItForFree.testItForFreeText3")}</p>

      <p className="topics">{t("testItForFree.disclaimerTitle")}</p>
      <p>
        {t("testItForFree.disclaimerText1")}
        <span className="bold">
          {t("testItForFree.disclaimerTextBold")}
        </span>{" "}
        {t("testItForFree.disclaimerText2")}
      </p>

      <p className="topics">{t("testItForFree.requirementsTitle")}</p>
      <div>
        <ul>
          <li>{t("testItForFree.requirement1")}</li>
          <li>
            <a
              className="links"
              style={{ marginBottom: 0 }}
              href="https://anydesk.com/"
              target="blank"
            >
              Anydesk software
            </a>{" "}
            {t("testItForFree.requirement2")}
          </li>

          <li>{t("testItForFree.requirement3")}</li>
          <li>{t("testItForFree.requirement4")}</li>
        </ul>
      </div>

      <p className="topics">{t("testItForFree.trainingTitle")}</p>
      <p>{t("testItForFree.trainingText")}</p>

      <p className="topics">{t("testItForFree.formTitle")}</p>
      <div>
        <SpinningMillForm />
      </div>
    </div>
  );
}
