const translation = {
  contact: {
    contactTitle: "联系我们",
    contactText: "随时为您效劳！",
    findAgents: "查找我们的代理",
    call: "致电垂询",
    english: "英语",
    englishWorkTime: "（GMT+1）13:00至17:00",
    portugueseAndSpanish: "葡萄牙语/西班牙语",
    portugueseWorkTime: "（GMT-3）8:00至18:00",
    email: "邮件垂询",
    queries: "任何疑问",
    support: "技术支持",
    location: "我们的位置",
  },
};

export default translation;
