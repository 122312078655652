const translation = {
  contact: {
    contactTitle: "Contáctenos",
    contactText: "¡Háganos saber cómo podemos ayudarlo!",
    findAgents: "Encuentre uno de nuestros agentes",
    call: "Llámanos",
    english: "Inglés",
    englishWorkTime: "(GMT+1) de 13:00 a 17:00",
    portugueseAndSpanish: "Portugués / Español",
    portugueseWorkTime: "(GMT-3) de 08:00 a 18:00",
    email: "Envíenos un correo electrónico",
    queries: "Cualquier consulta",
    support: "Apoio",
    location: "Nuestra ubicación",
  },
};

export default translation;
