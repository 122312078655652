const translation = {
  form: {
    selectOption: "Selecione uma opção",
    question1: "Sua empresa é uma fiação?",
    question1Options: {
      option1: "Sim",
      option2: "Não",
    },

    question2: "De qual país você é?",
    question3: "Qual o seu email?",
    question4: "Qual o seu número de telefone",

    question5:
      "Sua fiação tem máquina HVi? Como Uster’s? Loepfe’s? Premier’s? outras?",
    question5Options: {
      option1: "Sim, minha fiação tem algum tipo de máquina HVI",
      option2: "Não, não temos nada parecido.",
      option3: "Embora a nossa fiação tenha, não usamos",
      option4: "Não, mas pretendemos comprá-lo em um futuro próximo",
    },

    question6: "Qual o seu consumo mensal de algodão em toneladas? ",
    question7:
      "Quantas linhas de produção/linhas de abertura (exclusiva para algodão) há em sua empresa?",

    question8: "Há um gerente de qualidade trabalhando para sua fiação?",
    question8Options: {
      option1: "Sim, há sim",
      option2: "Não, não há ninguém",
    },

    question9: "Os fardos comprados/atuais são 100% testados? ",
    question9Options: {
      option1: "Sim, 100% dos fardos são 100% testados",
      option2: "Não. apenas 50% dos fardos são testados",
      option3: "Não mas cerca de 30% dos fardos são testados",
      option4: "Não mas 10% dos fardos são testados",
      option5:
        " Não, embora nada tenha sido testado, planejamos começar a testá-los em breve.",
    },

    question10: "Como são organizados os fardos no estoque atualmente?",
    question10Options: {
      option1: "Por lote",
      option2: "Por container",
      option3: "por caminhão",
      option4: "Algum outro formato",
    },

    question11:
      "Para prosseguir com o teste, nós precisamos um gerente de qualidade disponível para ser treiando por nós. Há alguém disponível para o treinamento?",
    question11Options: {
      option1: "Sim, há uma pessoa disponível",
      option2: "Não, não há ninguém disponível",
    },

    question12: "Quando você gostaria de iniciar o período de teste",
    question13:
      "Por favor, escreva abaixo o nome e endereço da empresa que você gostaria de executar o teste do OptCotton",
  },
};
export default translation;
