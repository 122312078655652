import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <div id="page-wrapper" className="flex">
      <div style={{ alignSelf: "center" }}>
        <h1>404 - Page not found!</h1>
        <p>The resource you are looking for does not exist. Make sure the url you entered is correct or try again later.</p>
        <div style={{ marginTop: 50 }}>
          <button className="main-button-blue" onClick={redirectToHome}>
          Home page
          </button>
        </div>
      </div>
    </div>
  );
}
