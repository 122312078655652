const translation = {
  home: {
    appTitle: "¡El software de gestión de pacas más eficiente!",
    appDescription:
      "OptCotton controla la calidad de las mezclas, manteniendo la estabilidad en la calidad, eliminando así problemas como la variación de color.",

    itmaInvitation: "Visítenos en Itma 2023",
    itmaDescription:
      "La exposición internacional de tecnología textil y de prendas de vestir más grande del mundo.",
    itmaLocale: "Nosotros estaremos ubicados en ",
    itmaDate: "08 - 14 Junio 2023",

    howItWorks: "¿Como funciona?",
    howItWorksText:
      "Desde el ingreso del algodón al inventario, OptCotton gestiona todo el proceso de mezcla, generando tendidos con calidad uniforme y brindando seguridad al proceso.",

    mixControlProcess: "Total control on mixing process",
    mixControlProcessText:
      "Nuestro sistema utiliza todos los parámetros de calidad de cualquier fuente HVI. El proceso de mezcla se planifica con precisión desde la calidad del inventario disponible hasta la disposición de los fardos en la línea de apertura.",
    mixControlProcessImg1: "Planeación de calidad",
    mixControlProcessImg2: "Separación de pacas",
    mixControlProcessImg3: "Selección de pacas",
    mixControlProcessImg4: "Arreglo de pacas",

    benefitSlider: {
      benefitTitle1: "Control de variación de color eficiente",
      benefitText1:
        "Tanto el uso de algoritmos sofisticados para las mezclas de algodón como la reducción de la variación en la calidad eliminan dicho riesgo.",

      benefitTitle2: "Mezclas uniformes",
      benefitText2:
        "Para evitar variaciones en los estándares de calidad, OptCotton utiliza tanto la calidad del inventario como la de las mezclas anteriores, por lo que no se aceptarán variaciones intolerables.",

      benefitTitle3: "No es necesario separar el inventario en clases",
      benefitText3:
        "Con una metodología única, no hay necesidad de separar las pacas en el almacén en clases según la calidad.",

      benefitTitle4: "Todos los parámetros HVI controlados en la mezcla",
      benefitText4:
        "Controla todos los parámetros del HVI, además de los parámetros internos requeridos en cada empresa.",
    },

    aiTitle: "Inteligencia artificial para la industria textil",
    aiText:
      "El futuro de la industria textil pasa por la Inteligencia Artificial para mezclar algodón. A través de su algoritmo, el sistema es capaz de analizar y seleccionar las pacas para los tendidos con el fin de dar estabilidad y seguridad al proceso de mezcla.",

    customersTitle: "Nuestros clientes",
    customersText:
      "Actualmente estamos en 11 países ubicados en América y Asia y ya representamos el 2% del algodón mezclado en el mundo. Vea a continuación algunos de nuestros clientes.",
  },
};

export default translation;
