const translation = {
  customers: {
    customersTitle: "Nuestros clientes",
    customersText1:
      "La versatilidad de OptCotton significa que puede usarse en cualquier proceso de mezcla en cualquier parte del mundo.",
    customersText2: "Nuestro software está presente actualmente en 7 países:",
    customersCountry:
      "Argentina, Bangladesh, Brasil, India, Indonesia, México, Pakistán, Paraguay, Tailandia, Turquía y Uzbekistán.",
    customersText3:
      "Pase el mouse sobre los países resaltados para ver algunas de las principales empresas textiles que dieron un paso hacia el futuro utilizando Optcotton.",

    customersText4:
      "Vea a continuación las empresas de cada país que optaron por dar un paso hacia el futuro eligiendo OptCotton:",
  },
};
export default translation;
