const translation = {
  testItForFree: {
    testItForFreeTitle: "免费试用",
    testItForFreeText1:
      "为了让您的棉纺厂了解软件的所有功能，并确保它能对您有所帮助，我们将会提供为期两个月的OptCotton免费试用。",
    testItForFreeText1Bold: "",
    testItForFreeText2:
      "在试用期间，您可以不受限制地使用软件的所有功能，包括专业团队的协助和指导。",
    testItForFreeText3:
      "免费试用的目的是让您对OptCotton进行评估，证实其在配棉过程中的有效性和安全性。此外，您还可以熟悉界面，更好地了解软件运行情况。",
    disclaimerTitle: "免责声明",
    disclaimerText1:
      "试用期仅限于一条生产线（清花间），并受WW SYSTEM内部规定和安装时间顺序的限制。在为期2个月的试用期内，我们的支持团队将全天候提供培训、指导和技术支持。",
    disclaimerTextBold: "",
    disclaimerText2: "",

    requirementsTitle: "技术要求：",
    requirement1:
      "运行Windows 07或更高版本的笔记本电脑/台式电脑，内存至少1GB，硬盘至少100GB。",
    requirement2: "已安装远程访问功能。",
    requirement3: "可随时上网。",
    requirement4:
      "数据库服务器。可以使用现有的MSSQL Server或Oracle数据库实例。如果公司的基础设施中没有运行任何数据库实例，我们可以安装MSSQL Server的快速实例（免费许可证）。",
    trainingTitle: "培训",
    trainingText:
      "为了让您的团队能够自如、安全地使用该软件，我们会就系统的主要功能和特点提供培训。理想情况下，您的团队中至少应有一人熟悉纤维质量参数，最好是质量经理。",
    formTitle: "感兴趣吗？请回答几个问题，以便我们进一步了解贵公司。",
    emailSent: "发送电子邮件",
    emptyFields: "填写所有字段",
  },
};
export default translation;
