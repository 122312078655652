const translation = {
  contact: {
    contactTitle: "Contact us",
    contactText: "Let us know how we can assist you!",
    findAgents: "Find one of our agents",
    call: "Call us",
    english: "English",
    englishWorkTime: "(GMT+1) from 1pm to 5pm",
    portugueseAndSpanish: "Portuguese / Spanish",
    portugueseWorkTime: "(GMT-3) from 8am to 6pm",
    email: "Email us",
    queries: "Any queries",
    support: "Support",
    location: "Our location",
  },
};

export default translation;
