const translation = {
  about: {
    aboutTitle: "About us",
    companyName: "WW Systems",
    aboutText:
      "Es una empresa líder en el desarrollo de sistemas de software para la industria algodonera. Estamos muy preocupados por la calidad en la producción textil. Estamos ubicados en el sur de Brasil, aproximadamente a 02 horas de vuelo desde São Paulo. Nuestro equipo está compuesto por ingenieros de software altamente experimentados con experiencia internacional en el proceso de hilado.",
    originTitle: "Origin",
    originText1:
      "Nuestra historia comenzó en 2004 con la creación de la empresa por parte del actual CEO, Jaison Werlich, quien ya desarrollaba software para el sector textil e identificó la necesidad de una herramienta que hiciera eficiente y seguro el proceso de elaboración de mezclas de algodón. A partir de entonces se crea WW Sistemas que, tras la internacionalización y en 2015, cambia su nombre a WW Systems",
    originText2:
      "Con una organización global de ventas y servicios, WW Systems mantiene su continuo crecimiento en ventas a través de la prospección constante principalmente en el mercado asiático. Las alianzas estratégicas también son una constante en la búsqueda de nuevos horizontes en el mercado global. Actualmente, además de varios agentes que operan en varios países, WW Systems ha conquistado importantes alianzas estratégicas, como ",
    rieterName: "Rieter",
    rieterCountry: "(Suiza).",
    imgDescription: "Sede WW Systems",
  },
};

export default translation;
