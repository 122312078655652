const translation = {
  testItForFree: {
    testItForFreeTitle: "Test it for free",
    testItForFreeText1:
      "So that your cotton mills can see all the functionality of the software in practice and be sure that it can help you, we are offering a ",
    testItForFreeText1Bold: "free two-month trial of OptCotton.",
    testItForFreeText2:
      "During this trial period, you will have access to all the software's functionalities without any restriction, including assistance and monitoring by a specialized team.",
    testItForFreeText3:
      "The purpose of this free trial is to allow you to evaluate OptCotton and prove its effectiveness and safety in the mixing process. In addition, you will be able to familiarize yourself with the interface and better understand how the software works.",
    disclaimerTitle: "Disclaimer",
    disclaimerText1: "The trial period is meant for ",
    disclaimerTextBold: "01 (one) operating line (blow room) ",
    disclaimerText2:
      "and will be subject to WW SYSTEM’s own internal regulations and availability respecting a temporal sequence of installations. Our support team will be 24/7 available during the 02-month trial period, providing training, guidance and technical support.",

    requirementsTitle: "Technical requirements:",
    requirement1:
      "A laptop/desktop computer running at least Windows 07 or higher with RAM of at least 1GB hard disk of at least 100GB.",
    requirement2: "installed for remote access.",
    requirement3: "Internet access at all times.",
    requirement4:
      "Database Server. An existing instance of MSSQL Server or Oracle Databse can be used. if the company does not have any database instance running in its infrastructure, we can install an express instance of MSSQL Server (free license).",
    trainingTitle: "Training",
    trainingText:
      "So that your team is comfortable and secure in using the software, we provide training on the main functions and features of the system. Ideally, at least one person on your team should be someone familiar with fiber quality parameters, preferably a quality manager.",
    formTitle:
      "Interested? Answer a few questions so that we can learn a little more about your company.",
    emailSent: "Email sent",
    emptyFields: "Fill in all fields",
  },
};
export default translation;
