const translation = {
  howItWorks: {
    howItWorksTitle: "How it works",
    howItWorksText1:
      "is a software for the elaboration and control of cotton laydowns that controls all HVI parameters, in addition to internal parameters created by each company with maximum precision and without the need to separate the inventory into classes.",
    howItWorksText2:
      "Its main purpose is the elimination of variations in quality among laydowns so that the yarn keeps its quality standards. The software manages the inventory since the cotton bales arrive until their consumption in the opening line.",
    howItWorksText3:
      "Planned and developed by a team with knowledge in both technology and mixing processes, the software offers a range of resources that provide total control over the yarn production process.",
    steps: "Briefly, these are the steps in the laydown creation process:",
    step1Title: "First, the definition of quality!",
    step1Text:
      "The mixing process starts with planning. Based on both available inventory quality and quality of the last laydown, the software is able to calculate the approximate quality for the next laydown with the",
    step1TextBold: "simple click of a button.",
    step2Title: "Then, let's get some bales from the inventory!",
    step2Text:
      "In the second step of the process, the system defines how many bales will be needed from each lot to create a pre-laydown. At the warehouse, the bales are physically separated for the selection process according to the quantity defined for each lot.",
    step2TextBold: "The bales that are most accessible can be picked up.",
    step3Title: "Let's go to the selection of bales for the laydown.",
    step3Text:
      "Through its algorithm, OptCotton analyzes the information of each bale picked up in the pre-laydown stage and makes the selection so that the average quality of the laydown is as close as possible to the quality planned in the initial stage. The selected bales are further sorted so that they are evenly arranged on the opening line.",
    step4Title: "Process planned, executed and result achieved.",
    step4Text:
      "As a result of the mixing process planned from inventory quality with the use of artificial intelligence, we have laydowns with uniform and stable quality.",
  },
};

export default translation;
