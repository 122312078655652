const translation = {
  home: {
    appTitle: "The most efficient bale management software!",
    appDescription:
      "OptCotton controls the quality of laydowns, maintaining stability in quality, thus eliminating problems such as shade variation.",

    itmaInvitation: "Visit us at Itma 2023",
    itmaDescription:
      "The world’s largest international textile and garment technology exhibition.",
    itmaLocale: "We will be located at",
    itmaDate: "08 - 14 June 2023",

    howItWorks: "How it works?",
    howItWorksText:
      "From the entry of cotton into the inventory, OptCotton manages the entire mixing process, generating laydowns with uniform quality and bringing security to the process.",

    mixControlProcess: "Total control on mixing process",
    mixControlProcessText:
      "Our system uses all quality parameters from any HVI source. The mixing process is accurately planned from the quality of the available inventory until the arrangement of the bales in the opening line.",
    mixControlProcessImg1: "Quality plannning",
    mixControlProcessImg2: "Separation of bales",
    mixControlProcessImg3: "Selection of bales",
    mixControlProcessImg4: "Arrangement of bales",

    benefitSlider: {
      benefitTitle1: "Efficient shade variation control",
      benefitText1:
        "Both the use of sophisticated algorithms for cotton laydowns and the reduction of variation in quality eliminate such risk.",

      benefitTitle2: "Uniformed laydowns",
      benefitText2:
        "In order to avoid variations in quality standards, OptCotton use both the inventory quality and that of the previous mixes then no intolerable variations will be accepted.",

      benefitTitle3: "No need to separare inventory into classes",
      benefitText3:
        " With a unique methodology, there is no need to separate bales in the warehouse into classes according to quality.",

      benefitTitle4: "All HVI parameters controlled in the laydown",
      benefitText4:
        " Controls all the HVI parameters, in addition to the internal parameters required in each company.",
    },

    aiTitle: "Artificial inteligence for textile industry",
    aiText:
      "The future in the textile industry relies on Artificial Intelligence to mix cotton. Through its algorithm, the system is able to analyze and select the bales for the laydowns in order to bring stability and security to the mixture process.",

    customersTitle: "Our customers",
    customersText:
      "We are currently in 11 countries located in America and Asia and already represent 2% of blended cotton in the world. See bellow some of our customers.",
  },
};

export default translation;
