import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { messages } from "./languages/messages";

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    detection: {
      order: ["navigator"], // Define a ordem de detecção do idioma: primeiro o navegador
    },
    resources: messages,
  });

export default i18n;
