import React, { useState, useEffect } from "react";
import "../../main.css";
import { SelectBox } from "devextreme-react";
import OptFace from "./../../images/opt-face.png";
import OptIn from "./../../images/opt-in.png";
import OptYoutube from "./../../images/opt-youtube.png";
import OptWhats from "./../../images/opt-whats.png";
import OptMenu from "./../../images/opt-menu.png";
import { useNavigate } from "react-router-dom";
import "./style.css";
import i18n from "../../i18n/i18n ";
import { useTranslation } from "react-i18next";

export default function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = navigator.language;
  const [selectedOption, setSelectedOption] = useState(language);

  const [menuExpanded, setMenuExpanded] = useState(false);

  useEffect(() => {
    function handleClick(event) {
      if (!event.target.closest("button") && menuExpanded == true) {
        setMenuExpanded(false);
      }
    }

    // adiciona o event listener no elemento document
    document.addEventListener("click", handleClick);

    // remove o event listener quando o componente for desmontado
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  function handleButtonClick(path) {
    setMenuExpanded(false);
    navigate(path);
  }

  function toggleMenu() {
    setMenuExpanded(!menuExpanded);
  }

  function changeLanguage(e) {
    setSelectedOption(e.target.value);
    i18n.changeLanguage(e.target.value);
  }

  return (
    <div id="header-wrapper">
      <div id="header-container">
        <div>
          <a
            style={{ marginBottom: 0 }}
            href="https://www.facebook.com/wwsystemsbrazil"
            target="blank"
          >
            <img className="social-icons" src={OptFace}></img>
          </a>
          <a
            style={{ marginBottom: 0 }}
            href="https://www.linkedin.com/company/optcotton/"
            target="blank"
          >
            <img className="social-icons" src={OptIn}></img>
          </a>
          <a
            style={{ marginBottom: 0 }}
            href="https://www.youtube.com/@wwsystems7970"
            target="blank"
          >
            <img className="social-icons" src={OptYoutube}></img>
          </a>
          <a
            style={{ marginBottom: 0 }}
            href="https://wa.me/5548996910179"
            target="blank"
          >
            <img className="social-icons" src={OptWhats}></img>
          </a>
        </div>
        <div>
          <select
            className="language-selector-mobile"
            value={selectedOption}
            onChange={changeLanguage}
          >
            <option className="language-option" value="pt-br">
              Português
            </option>
            <option className="language-option" value="en">
              English
            </option>
            <option className="language-option" value="es">
              Espanôl
            </option>
            <option className="language-option" value="zh">
              國語
            </option>
          </select>
        </div>
        <div id="header-buttons-wrapper">
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/")}
          >
            {t("buttons.home")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/about")}
          >
            {t("buttons.about")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/test-it-for-free")}
          >
            {t("buttons.testItForFree")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/contact")}
          >
            {t("buttons.contact")}
          </button>

          <select
            className="language-selector-desktop"
            value={selectedOption}
            onChange={changeLanguage}
          >
            <option className="language-option" value="pt-br">
              Português
            </option>
            <option className="language-option" value="en">
              English
            </option>
            <option className="language-option" value="es">
              Espanôl
            </option>
            <option className="language-option" value="zh">
              國語
            </option>
          </select>
        </div>
        <div id="menu-wrapper">
          <button
            id="menu-toggle"
            className="menu-toggle"
            onClick={toggleMenu}
            aria-expanded={menuExpanded}
          >
            <img className="social-icons" src={OptMenu}></img>
          </button>
        </div>
      </div>

      {menuExpanded && (
        <div id="header-buttons-mobile-wrapper">
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/")}
          >
            {t("buttons.home")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/about")}
          >
            {t("buttons.about")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/test-it-for-free")}
          >
            {t("buttons.testItForFree")}
          </button>
          <button
            className="header-buttons"
            onClick={() => handleButtonClick("/contact")}
          >
            {t("buttons.contact")}
          </button>
        </div>
      )}
    </div>
  );
}
