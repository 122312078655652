const translation = {
  form: {
    selectOption: "Seleccione una opción",
    question1: "¿Su empresa es una hilandería?",
    question1Options: {
      option1: "Sí",
      option2: "No",
    },

    question2: "¿Cuál es tu ubicación?",
    question3: "¿Cuál es tu e-mail?",
    question4: "¿Cuál es su contacto telefónico directo?",

    question5:
      "¿Su hilandería tiene una máquina HVI? ¿Como la de Uster? ¿La de Loepfe? ¿Premier? ¿Otros?",
    question5Options: {
      option1: "Sí, mi hilandería tiene algún tipo de máquina HVI",
      option2: "No, no tenemos nada similar.",
      option3: "Aunque nuestra hilandería sí tiene, no la usamos.",
      option4:
        "No, pero tenemos la intención de comprarlo en un futuro próximo.",
    },

    question6:
      "¿Cuál es el consumo mensual de algodón de su fábrica en toneladas?",
    question7:
      "¿Cuántas líneas de producción (exclusivo para algodón) tiene su hilandería?",

    question8: "¿Hay un gerente de calidad trabajando para su hilandería?",
    question8Options: { option1: "Sí hay", option2: "No, no hay" },

    question9:
      "¿Se han probado al 100 % las balas de algodón compradas/actuales?",
    question9Options: {
      option1: "Sí, el 100% de los fardos han sido probados",
      option2: "No, pero se han probado alrededor del 50 % de las pacas.",
      option3: "No, pero se han probado alrededor del 30% de las pacas.",
      option4: "No, pero el 10% de los fardos han sido probados",
      option5:
        "No, aunque no se ha probado nada, planeamos comenzar a probarlos en breve.",
    },

    question10: "¿Cómo se apilan las balas en el almacén actualmente?",
    question10Options: {
      option1: "En lote",
      option2: "Encontenedor",
      option3: "En camión",
      option4: "Algún otro método",
    },

    question11:
      "Para continuar con la prueba, necesitamos un gerente de calidad disponible para ser capacitado por nosotros. ¿Hay alguno disponible para entrenar?",
    question11Options: {
      option1: "Sí hay una.",
      option2: "No, no hay uno",
    },

    question12: "¿Cuándo quieres que empecemos la prueba?",
    question13:
      "Escriba su nombre completo junto con la dirección de la hilandería en la que le gustaría ejecutar la versión de prueba",
  },
};
export default translation;
